@use "sass:math"; @import "abstract";
.product-card-labels {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  &__chip {
    margin: 0 4px 4px 0;
  }
}
