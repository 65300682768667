@use "sass:math"; @import "abstract";
.advertising-banner-block {
  background-color: $black;
  background-repeat: no-repeat;
  background-size: cover;
  color: $white;

  @include mq($from: desktop) {
    padding: 304px 106px 48px;
  }

  @include mq($from: desktop, $until: 1920px) {
    background-position: top center;
  }

  @include mq($from: 1920px) {
    background-position: center;
  }

  @include mq($from: tablet, $until: desktop) {
    background-position: bottom center;
  }

  @include mq($until: tablet) {
    background-position: top center;
    padding: 186px 0 48px;
    padding-left: $mobilePageWrapperPadding !important;
    padding-right: $mobilePageWrapperPadding !important;
  }

  .page-wrapper {
    @include mq($until: tablet) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &_with-benefits {
    @include mq($from: tablet) {
      padding: 176px 106px 40px;
    }

    @include mq($until: tablet) {
      padding: 169px 0 48px;
    }

    &.advertising-banner-block::before {
      height: rem(227);
    }
  }

  &_with-offer-sign {
    overflow: visible !important;

    &::before {
      @include mq($from: tablet) {
        height: rem(227) !important;
      }

      @include mq($until: tablet) {
        height: mRem(350) !important
      }
    }
  }

  &_with-description {
    @include mq($from: tablet) {
      padding-bottom: 48px;
    }

    @include mq($until: tablet) {
      padding-bottom: 48px;
    }
  }

  &_slim {
    @include mq($from: tablet) {
      padding: 187px 105px 40px;
    }

    @include mq($until: tablet) {
      padding-top: 297px;

      .row_centered-sm {
        margin: auto;
      }

      .advertising-banner-block-content__title {
        line-height: 1.5;
        margin-bottom: 32px;
        max-width: 100%;

        br {
          display: none;
        }
      }
    }

    &.with-blurred-bottom-block::before {
      @include mq($from: tablet) {
        height: rem(153);
      }

      @include mq($until: tablet) {
        height: mRem(209)
      }
    }
  }

  &_price {
    padding-bottom: 48px;
    padding-left: 64px;


    .advertising-banner-block-content__title {
      max-width: rem(700);
    }

    &.with-blurred-bottom-block::before {
      height: rem(380);
    }
  }

  &_top-banner {
    @include mqProp('margin-top', -60px, -48px, -59px);
    @include mqProp('padding-top', 198px, 187px, 211px,);

    padding-left: 0;
    padding-right: 0;
  }

  &_squeezed {
    @include mq($from: 1316px) {
      margin-left: auto;
      margin-right: auto;
      width: rem(1268);
    }

    @include mq($from: desktop, $until: 1316px) {
      margin-left: 24px;
      margin-right: 24px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-left: 38px;
      margin-right: 38px;
    }

    @include mq($until: tablet) {
      padding: 276px 16px 48px;
      padding-left: 16px !important;
    }

    @include mq($from: tablet) {
      padding: 142px 105px 40px;
    }

    &.advertising-banner-block::before {
      @include mq($from: tablet) {
        height: rem(180);
      }

      @include mq($until: tablet) {
        height: mRem(255);
      }

      @include mq($until: 365px) {
        height: mRem(218);
      }

      @include mq($until: 320px) {
        height: mRem(237);
      }
    }
  }
}

.advertising-banner-block-content {
  position: relative;

  @include mq($until: tablet) {
    align-items: flex-start !important;
    flex-direction: column;
  }

  .space-holder8.space-holder8-sm {

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__text.advertising-banner-block-content__text {
    @include mq($until: tablet) {
      line-height: 1.45;
      max-width: mRem(312);

      &.advertising-banner-block-content__text {
        font-size: 1rem;
      }
    }
  }

  &__description {
    display: grid;

    @include mq($from: tablet) {
      align-items: center;
      grid-template-columns: 60% 40%;
    }

    @include mq($until: tablet) {
      grid-row-gap: 24px;
      line-height: 1.5;
    }

    p {
      @include mq($from: tablet) {
        min-width: rem(526);
      }
    }
  }

  &__title {
    @include mq($from: tablet) {
      max-width: rem(600);
    }

    @include mq($until: tablet) {
      max-width: mRem(312);

      &.h2 {
        margin-bottom: 24px;
      }
    }
  }
}

.advertising-banner-button {
  @include mq($until: tablet) {
    margin-left: auto;
    margin-right: auto;
  }

  &_litter {
    height: rem(48);
  }
}

.advertising-banner-banner-sign {
  align-items: center;
  bottom: 100%;
  position: absolute;
  text-transform: uppercase;
  transform: translateY(50%);

  @include mq($until: tablet) {
    left: 29px;
  }
}

.advertising-banner-slim-block {

  &__button {
    @include mqProp('margin-right', 20%, auto);
  }
}

.advertising-banner-block_with-footer {
  @include mqProp('padding-bottom', 32px, 16px);

  &.advertising-banner-block::before {
    @include mq($from: tablet) {
      height: rem(100) !important;
    }

    @include mq($until: tablet) {
      height: mRem(90) !important;
    }
  }
}


.advertisingBannerBlockContentPrice {
  margin-top: 24px;
  width: 650px;

  &__initial {
    display: inline;
    font-family: $headingFontFamily;
    font-size: rem(40);
    font-weight: bold;
    line-height: 1.4;
    opacity: 0.5;
    position: relative;

    &::after {
      background-color: #fff;
      border-radius: 5px;
      content: "";
      height: 0.125rem;
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(-15deg);
      transform-origin: 100% 13px;
      width: 100%;
    }
  }

  &__value {
    font-family: $headingFontFamily;
    font-size: rem(40);
    font-weight: bold;
    margin-top: 8px;
  }

  &__description {
    margin-top: -3px;
  }
}

@include mq($until: desktop) {

  .advertising-banner-block {

    &_price {

      .advertising-banner-block-content__title {
        max-width: 100%;
      }
    }
  }
}


@include mq($from: tablet, $until: desktop) {

  .advertising-banner-block {

    &_price {
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 180px;

      &.with-blurred-bottom-block::before {
        height: rem(420);
      }
    }
  }

  .advertisingBannerBlockContentPrice {
    width: auto;

    &__value {
      margin-top: 0;
    }
  }
}

@include mq($until: tablet) {

  .advertising-banner-block {

    &_price {
      padding: 100px 24px 24px;

      .advertising-banner-block-content__title {
        margin-bottom: 0;
      }

      .advertising-banner-button {
        margin-top: 32px;
      }

      &.with-blurred-bottom-block::before {
        height: rem(330);
      }
    }
  }

  .advertisingBannerBlockContentPrice {
    margin-top: 0;

    &__initial, &__value {
      font-size: 16px;
    }

    &__description {
      font-size: 12px;
    }
  }
}
