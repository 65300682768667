@use "sass:math"; @import "abstract";
.toggle-displaying-items-button {
  @include mqProp('padding-left', 40px, 24px);

  align-items: center;
  color: $black;
  cursor: pointer;
  display: flex;
  position: relative;

  &__button {
    appearance: none;
    background: none;
    border: 0;
    margin: 0;
    padding: 0;
  }

  &__arrow {
    @include scaledRem('width', 16);
    @include scaledRem('height', 16);
    @include mqProp('left', 4px, 0);

    background:url('/assets/icons/flat/arrow-down.svg') no-repeat;
    position: absolute;
    transition: transform 250ms $sharp;
    will-change: transform;
  }

  &__button:checked + &__arrow {
    transform: rotate(180deg);
  }
}
