@use "sass:math"; @import "abstract";
.exact-farming-checks-phone-section {
  padding-top: 96px;

  &__inner {
    background-color: $white;
    background-repeat: no-repeat;

    @include mq($from: desktop) {
      background-position: calc(50% + 355px) 69px;
      background-size: 400px;
      min-height: 570px;
      padding: 64px;
    }

    @include mq($from: tablet, $until: desktop) {
      background-position: calc(50%) calc(100% + 140px);
      padding: 48px;
      padding-bottom: 420px;
    }

    @include mq($until: tablet) {
      background-position: calc(50%) calc(100% + 160px);
      background-size: 311px;
      padding: 23px;
      padding-bottom: 400px;
    }
  }

  &__title {
    @include mq($from: desktop) {
      width: 738px;
    }
  }

  &__checks-wrapper {
    @include mq($from: desktop) {
      max-width: 545px;
    }
  }

  &__check {
    font-family: $headingFontFamily;
    font-weight: bold;
    opacity: 0.6;
    padding-left: 42px;
    position: relative;

    &:not(:last-child) {
      @include mqProp('margin-bottom', 27px, 9px, 24px);
    }

    &::before {
      background-image: url('/assets/icons/check-green.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 12px;
      left: 6px;
      position: absolute;
      top: 6px;
      width: 16px;
    }
  }
}
