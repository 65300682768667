@use "sass:math"; @import "abstract";
.hint-wrapper-modal-content {

  &.hint-wrapper-modal-content {
    padding: 16px 40px 16px 24px;
  }
}

.hint-modal {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed !important;

  .inner-modal-content {
    max-width: rem(400);
    min-width: rem(320);
    padding: 24px;
  }
}
