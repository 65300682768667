@use "sass:math"; @import "abstract";
.exact-farming-header-container {
  @include mq($from: tablet, $until: desktop) {
    min-width: 551px;
  }
}

.exact-farming-header-image-container {
  flex-shrink: 0;
  
  &.exact-farming-header-image-container {
    @include mqProp('width', 990px, 679px, 679px);

    @include mq($until: tablet) {
      margin: 0 0 -175px;
    }
  }

  &__image {
    width: 100%;
  }
}

.exact-farming-header {
  display: flex;

  @include mq($from: desktop) {
    padding-bottom: 64px;
  }
  
  @include mq($from: tablet, $until: desktop) {
    padding-bottom: 96px;
  }
  
  @include mq($from: tablet) {
    padding-top: 12px;
  }

  @include mq($until: tablet) {
    flex-direction: column;
    overflow: hidden;
    padding-top: 7px;
  }

  &__subtitle-text {
    @include mq($from: desktop) {
      margin-bottom: 69px;
      width: 526px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 39px;
      width: 402px;
    }

    @include mq($until: tablet) {
      padding-bottom: 32px;
    }
  }
}

.exact-farming-header-price-old-price {
  font-family: $headingFontFamily;
  font-weight: bold;
  height: 24px;
  opacity: 0.4;
  text-decoration: line-through;
}

.exact-farming-header-price {
  color: $black;
  font-family: $headingFontFamily;
  font-weight: bold;

  &__value {
    @include scaledRem('font-size', 38, 19);

    @include mq($from: tablet) {
      margin-right: 5px;
    }
  }

  &__per {
    opacity: 0.4;
  }
}

.exact-farming-header-action-row {
  align-items: center;
  display: flex;

  &__button {
    white-space: nowrap;

    @include mq($until: tablet) {
      width: 167px;
    }
  }

  &__logo {

    &_mobile {
      margin-bottom: 17px;
      width: 108px;
    }
  }
}
