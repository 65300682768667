@use "sass:math"; @import "abstract";
.article-preview-without-image-link {
  @include mqProp('padding', 63px 32px 32px, 24px);

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.article-preview-without-image {
  @include mqProp('width', calc(33.33% - 6px), calc(100% + 48px), calc(50% - 6px));
  @include scaledRem('min-height', 480, 391, 387);

  background-color: $white;
  flex-shrink: 0;

  @include mq($until: tablet) {
    margin-left: -24px;
  }
}
