@use "sass:math"; @import "abstract";
.lot-price {
  @include mq($from: tablet) {
    white-space: nowrap;
    width: 33%;
  }

  &-index {

    &__label {
      color: $color-text-secondary;
    }
  }

  .uom {
    @include scaledRem('font-size', 14, 12);

    color: $paleBlack;
    font-weight: 400;

    @include mq($until: tablet) {
      vertical-align: bottom;
    }
  }
}

.lot-price-tip {
  @include mqProp('margin-bottom', -4px, 2px);

  align-items: center;
  display: flex;

  &__text {
    @include mqProp('margin-right', 11px, 8px);

    font-family: $headingFontFamily;
  }
}
