@use "sass:math"; @import "abstract";
.callIcon {
  color: $white;

  @include mq($until: tablet) {
    color: $color-text-secondary;
    font-size: 16px;
  }

  &:hover {
    color: $green;
  }
}
