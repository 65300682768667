@use "sass:math"; @import "abstract";
.radio-buttons-group {

  .rs-radio-group-picker {
    border: 0;
    color: $mainTextColor;
    margin-left: rem(-4);
    width: calc(100% + #{rem(8)});

    .rs-radio-inline {
      border: 2px solid rgba(86, 89, 82, .2);
      border-radius: 25px;
      margin: 0 rem(4) rem(9);
      padding: rem(5) rem(18);
      transition: border .2s $sharp;
      will-change: border;

      &.rs-radio-checked {
        background: $lightGreen;
        border-color: $lightGreen;
      }
    }

    .rs-radio-checker> label {
      color: $mainTextColor;
      font-size: rem(14);
      font-weight: 400;
      line-height: 1;
      will-change: border, color, font-weight;

      transition: {
        duration: .2s;
        property: border, color, font-weight;
        timing-function: $sharp;
      }
    }

    .rs-radio-checked .rs-radio-checker> label {
      color: $mainTextColor;
      font-weight: 400;
    }
  }
}
