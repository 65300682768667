@use "sass:math"; @import "abstract";
.progress-steps-container-wrapper {
  position: relative;

  &::after {
    bottom: -10px;
    content: "";
    left: -15px;
    pointer-events: none;
    position: absolute;
    right: -15px;
    top: -10px;

    @include mq($from: tablet) {
      background: linear-gradient(90deg, $white 6%, transparent 10%, transparent 90%, $white 95%);
    }

    @include mq($until: tablet) {
      background: linear-gradient(90deg, $white 15%, transparent 20%, transparent 85%, $white 90%);
    }
  }
}

.progress-steps-container {
  max-width: 100%;
  -ms-overflow-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  scrollbar-width: none;

  @include mq($from: desktop) {
    height: rem(60);
    padding-bottom: 27px;
  }

  @include mq($until: desktop) {
    margin: 24px 0;
    min-height: mRem(66);
  }

  @include mq($until: tablet) {
    padding-bottom: 48px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .rs-steps-item-description {
    color: $black;
    display: inline-block;

    @include mq($from: tablet) {
      font-size: rem(14);
      line-height: em(21, 14);
      margin-top: 16px;
      transform: translateX(rem(-24));
      white-space: nowrap;
    }

    @include mq($until: tablet) {
      font-size: mRem(12);
      line-height: 1.5;
      margin-top: 19px;
      transform: translateX(mRem(-24));
    }
  }

  .rs-steps {
    position: relative;

    &::before, &::after {
      content: '';
      position: absolute;
    }
  }

  .rs-steps-horizontal .rs-steps-item-title {

    &::after {
      background-image: linear-gradient(90deg, rgba($black, .2) 30%, transparent 30%);
      background-repeat: repeat-x;
      background-size: .24rem .1rem;
      border: 0;
      left: 0;
      top: 50%;
      transform: translateY(50%);
      width: 100%;

      @include mq($from: tablet) {
        height: rem(3);
      }

      @include mq($until: tablet) {
        height: mRem(3);
      }
    }
  }

  .rs-steps-item {
    @include mq($until: tablet) {
      flex-shrink: 0;
      width: mRem(104);
    }
  }

  .rs-steps-item-title {
    width: 100%;
  }

  .rs-steps-item-icon-wrapper.rs-steps-item-icon-wrapper {
    @include scaledRem('width', 24);
    @include scaledRem('height', 24);

    background-color: transparent;
    border: 0;
    position: static;
  }

  .rs-steps-horizontal .rs-steps-item-content {
    @include scaledRem('height', 25);
  }

  .rs-steps-horizontal .rs-steps-item.rs-steps-item {
    display: flex;
    padding: 0;
  }

  .rs-steps-item-icon-wrapper > .rs-steps-item-icon {
    @include scaledRem('width', 24);
    @include scaledRem('height', 24);

    align-items: center;
    background-color: $white;
    border: solid $lightGray;
    border-radius: 100%;
    border-width: 6px;
    display: flex;
    font-size: 0;
    justify-content: center;
    margin: 0;
    position: relative;

    &.rs-steps-item-icon-process {
      border-color: $green;
    }

    &.rs-steps-item-icon-finish {
      background-color: $green;
    }
  }

  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
    content: none;
  }

  .rs-steps-item-status-wait {

    .rs-steps-item-description {
      color: rgba($black, .4);
    }
  }

  .rs-steps-item {
    overflow: visible;
  }

  &_swipeable {
    @include mqProp('padding-left', 70px, 50px);

    @include mq($from: tablet) {
      height: rem(100);
    }

    @include mq($until: tablet) {
      padding-right: 30px;
    }

    .rs-steps-item-description {
      text-align: center;
      transform: translateX(-60%);
      white-space: normal;
    }

    .rs-steps {
      transition: transform 250ms ease-in-out;
    }

    .rs-steps-item {
      @include mqProp('min-width', 20%, 40%);
    }
  }
}

