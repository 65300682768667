@use "sass:math"; @import "abstract";
.horizontal-scrollable-block-container {
  position: relative;

  &_was-scrolled {

    &::after {
      bottom: -10px;
      content: "";
      left: -15px;
      pointer-events: none;
      position: absolute;
      right: -15px;
      top: -10px;
      width: 100%;
  
      @include mq($from: tablet) {
        background: linear-gradient(90deg, $white 6%, transparent 10%, transparent 90%, $white 95%);
      }
  
      @include mq($until: tablet) {
        background: linear-gradient(90deg, $white 15%, transparent 20%, transparent 85%, $white 90%);
      }
    }
  }
}

.horizontal-scrollable-block {

  &__arrow {
    @include scaledRem('width', 48);
    @include scaledRem('height', 48);

    padding: 0;

    @include mq($until: tablet) {
      margin-right: 0;
    }
    
    path, circle {
      transition: fill 250ms $sharp;
    }

    &:hover, &:focus {

      path, circle {
        fill: $green;
      }
    }

    svg {
      margin-right: 0;
    }

    &_left {
      margin-left: 0;
    }

    &_right {
      margin-left: auto;
      order: 2;
    }
  }
}

.horizontal-scrollable-block-arrows {
  @include mqProp('margin-top', -65px, -45px);

  display: flex;
}
