@use "sass:math"; @import "abstract";
.simple-details {
  display: grid;
  grid-row-gap: 8px;
  width: 100%;
}

.simple-details-property {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: relative;

  &_editing {
    @include mq($until: tablet) {
      align-items: flex-start;
      display: grid;

      .dot-spacer {
        display: none;
      }
    }

    &:not(:last-child) {
      @include mq($until: tablet) {
        margin-bottom: mRem(8);
      }
    }
  }

  &_editing {

    .dot-spacer {
      margin: 0;
      position: relative;
      top: 5px;
    }
  }

  &_editing {
    @include mq($until: tablet) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      grid-row-gap: mRem(8);

      .dot-spacer {
        display: none;
      }
    }
  }

  &__value {
    text-align: right;

    @include mq($until: tablet) {
      width: fit-content;
    }

    .input-wrapper__error {
      @include mq($from: tablet) {
        position: absolute;
        right: 0;
        top: 100%;
      }
    }

    &_previous {
      @include scaledRem('margin-bottom', -4);

      margin-left: auto;
      max-width: 50%;
      text-align: right;
    }

    &_editable {
      @include mq($from: tablet) {
        width: rem(380);
      }

      @include mq($until: tablet) {
        text-align: left;
        width: 100%;
      }

      .rs-picker-none {
        font-size: mRem(14);
      }
    }

    &_not-editable {
      background-color: $lightGray;
      opacity: .6;
      overflow-y: auto;
      text-align: left;

      @include mq($from: tablet) {
        max-height: rem(90);
        min-height: rem(48);
        padding: rem(13) rem(24) rem(14);
        width: rem(380);
      }

      @include mq($until: tablet) {
        font-size: mRem(14);
        line-height: 1.45;
        max-height: mRem(90);
        min-height: mRem(40);
        padding: mRem(12) mRem(30) mRem(11) mRem(16);
        width: 100%;
      }
    }
  }

  &__detailsDescriptionId {
    color: $color-text-tertiary;
    display: flex;
    justify-content: flex-end;
    text-align: end;
  }

  &__editDetailsDescriptionId {
    max-width: 380px;
    text-align: start;
  }

  &__label {
    align-self: flex-start;
    background-color: $white;
    color: $paleBlack;
    margin-top: auto;
    transition: transform 250ms $sharp;
    width: fit-content;
    will-change: transform;

    + .dot-spacer {
      @include mqProp('transform', translateY(-4px), translateY(-1px));
    }

    &_editing {
      @include mq($from: tablet) {
        transform: translateY(4px);
      }

      @include mq($until: tablet) {
        font-size: mRem(14);
        line-height: mRem(17);
      }
    }
  }
}

.simple-details-title {
  @include mq($from: tablet, $until: desktop) {
    margin-bottom: 37px;
  }

  @include mq($until: tablet) {
    line-height: mRem(24);
  }
}
