@use "sass:math"; @import "abstract";
.teaser {
  align-items: center;
  display: flex;

  &__ico {
    flex-shrink: 0;

    @include mq($from: tablet) {
      margin-right: rem(16);
    }
    
    @include mq($until: tablet) {
      height: mRem(8);
      margin-right: mRem(8);
    }
  }
}
