@use "sass:math"; @import "abstract";
.document-card-mobile-buttons {
  @include mq($until: desktop) {
    display: grid;
    grid-row-gap: 16px;
    grid-template-columns: 100%;
  }

  @include mq($from: tablet, $until: desktop) {
    grid-column-start: 1;
    justify-self: flex-start;
  }

  @include mq($until: tablet) {
    grid-column: span 2;
  }
}

.document-card {
  align-content: flex-start;
  align-items: flex-start;
  background-color: $white;
  border-left: 4px solid transparent;
  display: grid;
  padding: 32px 40px 32px 36px;
  position: relative;
  transition: border-color 250ms $sharp;
  will-change: border-color;
  
  @include mq($from: desktop) {
    grid-template-columns: 56.45% 23.93% 19.53%;
  }

  @include mq($from: tablet, $until: desktop) {
    grid-template-columns: 72% 28.3%;
    padding: 31px 72px 32px 34px;
  }
  
  @include mq($until: tablet) {
    grid-template-columns: repeat(2, 1fr);
    padding: 24px;
    padding-left: 20px;
  }

  @include mq($until: desktop) {
    grid-row-gap: 16px;
  }

  &__rejecting-hint {
    @include mq($until: desktop) {
      grid-column-start: 1;
    }
  }

  &__rejecting-hint-modal {
    @include mq($from: tablet, $until: desktop) {
      min-width: rem(420);
      width: rem(420);

      p {
        font-size: rem(14)
      }
    }
  }

  & + & {
    @include mqProp('margin-top', 4px, 2px);
  }

  &__name {
    @include mq($from: desktop) {
      padding-right: 54px;
    }
  }

  &:hover, &:focus, &_active {
    border-color: $green;
  }
}

.document-card-actions {
  @include mq($from: desktop) {
    column-gap: 16px;
    display: flex;
  }

  @include mq($until: desktop) {
    display: grid;
    grid-template-columns: repeat(3, #{mRem(16)});
  }

  @include mq($from: tablet, $until: desktop) {
    grid-gap: 19px;
  }

  @include mq($until: tablet) {
    grid-gap: 16px;
  }

  svg {
    margin: 0;
  }

  &__button {
    display: flex;
    opacity: .2;
    padding: 0;
    transition: opacity 250ms $sharp;
    will-change: opacity;

    &:hover, &:focus {
      opacity: 1;
    }

    &--iconColorBlack {
      color: $black;
    }

    &--iconColorGreen {
      color: $green;
    }

    &--iconColorRed {
      color: $red;
    }
  }

  &__button_rejected {
    opacity: 1;
  }
}

.document-card-hint-container {
  width: max-content;
}

.document-card-first-col {
  @include mq($until: tablet) {
    grid-column: span 2;
  }
}

.document-card-third-col {
  @include mq($until: desktop) {
    grid-column-start: 2;
    grid-row-start: 2;
    justify-self: flex-end;
  }

  @include mq($from: tablet, $until: desktop) {
    grid-row-start: 1;
  }

  &:not(&_not-shrunk) {
    @include mq($until: desktop) {
      width: mRem(103);
    }
  }
}
