@use "sass:math"; @import "abstract";
.orderHeader {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 56px 45px 40px 40px;

  &__wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  &__statusMessage {
    margin-top: 4px;
  }

  &__status {
    white-space: normal;
  }

  &__statusTitle {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__date {
    color: rgba($black, 0.4);
  }

  &__rowMultiOrder {
    align-items: center;
    display: flex;
  }
}

@include mq($from: tablet, $until: desktop) {

  .orderHeader {
    margin-top: 76px;
    padding: 56px 45px 40px 38px;
  }
}

@include mq($until: tablet) {

  .orderHeader {
    flex-wrap: wrap;
    margin-top: mRem(2px);
    padding: $mobilePageWrapperPadding;

    &__status {
      margin-top: 16px;
    }

    &__title {
      flex-basis: 100%;
    }

    &__date {
      display: block;
      margin-top: mRem(4);
    }
  }
}
