@use "sass:math"; @import "abstract";
.checkboxes-filter {
  @include mq($from: desktop) {
    max-width: rem(270);
  }

  &:not(:last-child) {
    margin-bottom: 32px;

    @include mq($from: tablet) {
      margin-bottom: 48px;
    }

    @include mq($until: tablet) {
      margin-bottom: 24px;
    }
  }

  &_in-modal {
    background-color: $white;
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    overflow: hidden;
    padding: 76px 24px 24px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2004;

    @include mq($from: tablet, $until: desktop) {
      height: calc(100vh - 68px);
    }

    @include mq($from: tablet) {
      margin-top: 44px;
    }

    @include mq($from: 360px, $until: tablet) {
      height: calc(100vh - 83px);
    }

    @include mq($until: 360px) {
      height: calc(100vh - 136px);
    }
  }

  &__items {
    display: grid;

    @include mq($from: desktop) {
      grid-row-gap: 16px;
    }

    @include mq($from: tablet, $until: desktop) {
      grid-gap: 16px 3px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq($until: tablet) {
      flex-wrap: wrap;
      grid-gap: 17px 16px;
    }
  }

  &-search-no-results {
    @include scaledRem('font-size', 14);

    color: rgba(46, 32, 50, 0.5);
    padding-bottom: 24px;

    @include mq($until: desktop) {
      font-size: rem(14);
      padding-top: 32px;
      text-align: center;
    }
  }

  &-search-char-label {
    @include mq($from: tablet, $until: desktop) {
      grid-column: 1 / span 2;
    }
  }

  &-search-wrapper {
    @include mq($from: tablet, $until: desktop) {
      grid-column: span 2;
    }

    @include mq($until: desktop) {
      display: grid;
      grid-gap: 16px;
    }

    .search-input__clear-button {

      svg {
        @include mq($from: desktop) {
          height: 16px;
          width: 16px;
        }
      }
    }

    .search-input__icon {
      @include mq($from: desktop) {
        right: 16px;
      }

      svg {
        @include mq($from: desktop) {
          height: 16px;
          padding-top: 2px;
          width: 14px;
        }
      }
    }

    svg {
      @include mq($from: desktop) {
        height: 12px;
        width: 12px;

        *[stroke] {
          stroke: rgba(46, 32, 50, 0.5);
        }
      }
    }

    .input-wrapper__input {
      @include mq($from: desktop) {
        background: $white;
        border: 1px solid rgba(46, 32, 50, 0.2);
        border-radius: 8px;
        height: 40px;
        padding: 12px 16px;
      }
    }
  }

  &-search-char-container {
    display: grid;
    grid-gap: 16px;
    max-height: 400px;
    overflow-y: auto;

    @include mq($from: tablet, $until: desktop) {
      grid-column: span 2;
    }

    &_in-modal {
      max-height: calc(100vh - 263px);
      padding-bottom: 24px;

      @include mq($from: tablet, $until: desktop) {
        max-height: calc(100vh - 350px);
      }
    }
  }

  &-search-input input {
    @include mq($from: desktop) {
      display: flex;
      min-height: 1rem;
      width: 216px;
    }

    &, &::placeholder {
      @include scaledRem('font-size', 12, 14, 14);
    }
  }
}

.popup-back-icon svg {
  @include mq($until: desktop) {
    height: 36px;
    width: 36px;
  }

  @include mq($until: tablet) {
    height: 24px;
    width: 26px;
  }
}
