@use "sass:math"; @import "abstract";
.productPrice {
  margin-bottom: -4px;
  width: 120px;

  &--index {
    display: flex;
  }

  &__item {
    display: inline-block;
    text-decoration: inherit;
  }

  &__price {
    font-size: 20px;
  }

  &__fraction {
    @include scaledRem('font-size', 16, 14);

    font-family: $fontFamilyBase;
    font-weight: 400;
  }
}

@include mq($until: tablet) {

  .productPrice {

    &__item {
      font-size: 16px;
    }

    &__price {
      font-size: 14px;
    }
  }
}

@include mq($from: tablet) {

  .productPrice {

    &__item {
      font-size: 18px;
    }

    &__price {
      font-size: 16px;
    }
  }
}
