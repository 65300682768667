@use "sass:math"; @import "abstract";
.header-mobile-litter {
  height: mRem(52);

  @supports (position: sticky) {
    height: auto;
  }
}

.header-tablet-litter {
  height: rem(120);

  @supports (position: sticky) {
    height: auto;
  }
}

.header {
  position: fixed;
  top: 0;
  transition: transform 250ms ease-in-out;
  width: 100%;
  z-index: 2004 !important;

  @include mq($from: tablet) {
    color: $white;
  }

  &_throttle {
    position: fixed;

    @supports (position: sticky) {
      position: relative;
    }
  }

  @supports (position: sticky) {
    position: sticky !important;
  }

  &:hover {
    z-index: 2000;
  }

  &_up {
    transform: translateY(0);
  }

  &_down {
    transform: translateY(-100%);
  }
}

.header-actions-list {
  display: flex;

  @include mq($from: tablet, $until: desktop) {
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    width: 100%;
  }

  @include mq($until: tablet) {
    gap: 4px;
  }

  &_authed {
    @include mq($from: desktop) {
      margin-bottom: auto;
      margin-left: auto;
      padding-left: 32px;
    }

    @include mq($until: tablet) {
      align-items: center;
      justify-content: space-between;
      padding-bottom: 4px;
      padding-left: 16px;
      width: 100%;
    }
  }

  &_not-authed {
    @include mq($from: desktop) {
      margin-left: auto;
      max-width: 180px;
      overflow: hidden;
    }

    @include mq($until: tablet) {
      align-items: center;
      padding-bottom: 4px;

      .header-action_call-action {
        margin-right: 24px;
      }

      .header-action_profile-action {
        margin-right: 3px;
      }
    }

    @include mq($from: tablet, $until: desktop) {
      color: $white;
    }
  }
}

.header-action {

  &_menu {
    @include mq($until: tablet) {
      margin-left: 4px !important;
    }
  }

  &_profile-action {
    @include mq($from: desktop) {
      margin-right: 48px;
    }

    @include mq($until: mobile) {
      margin-right: 4px !important;
    }

    @include mq($from: tablet) {
      align-items: center;
      display: flex;
    }

    @include mq($until: tablet) {
      margin-right: auto;
    }
  }

  &_call-action {
    margin-left: 10px;

    @include mq($from: desktop) {
      display: none;
    }

    @include mq($from: mobile, $until: tablet) {
      margin-left: 12px;
      margin-right: 12px;
    }

    @include mq($until: tablet) {
      position: relative;
      top: 2px;

      path {
        stroke: $black !important;
      }
    }

    @include mq($until: mobile) {
      margin-left: 8px;
      margin-right: 8px;
    }
  }
}

.header-logo {
  align-items: center;
  background-color: $green;
  display: flex;
  justify-content: flex-start;
  padding-right: rem(116);
  position: relative;

  @include mq($until: tablet) {

    & {
      padding-right: 1rem;
    }

    svg {
      height: 52px;
      width: 104px;
    }
  }

  &::before {
    background-color: $green;
    content: '';
    height: 100%;
    position: absolute;
    right: 100%;
    top: 0;
    width: 100vw;
  }
}

.header-bottom-navigation {
  display: flex;
  height: 100%;
}

.header-top {
  background-color: $black;
  height: rem(48);
  padding: rem(8) 0 rem(11);
  position: relative;
  white-space: nowrap;
  z-index: 100000;

  @include mq($from: desktop) {

    .user-region-chooser__button {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 177px;
    }
  }

  @include mq($from: tablet, $until: desktop) {

    .user-region-chooser__button {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 177px;
    }
  }

  .page-wrapper {
    display: flex;
  }

  &__region {

    &_tablet {
      flex-shrink: 0;
      margin-left: -8px;
    }
  }
}

.nav-list-item {

  &_weather-info {

    + * {
      margin-left: auto;
    }
  }

  &_language {
    margin-left: 16px;
    min-width: 77px;
  }
}

.header-bottom {
  align-items: center;
  background-color: $white;
  color: $black;
  display: flex;
  height: rem(72);
  z-index: 2;

  .page-wrapper {
    height: 100%;
    position: relative;
  }
}

.header-menu-button {
  @include mq($from: tablet, $until: desktop) {
    margin-bottom: auto;
    margin-left: 64px;
    margin-top: auto;
  }

  svg {
    @include mq($from: tablet, $until: desktop) {
      margin-right: 8px;
    }

    @include mq($until: tablet) {
      margin-right: 0;
    }
  }
}

.slow-connection,
.disabled-js {

  .header_down {
    animation: fade-in-up 200ms ease forwards;
  }
}

@include mq($from: tablet, $until: desktop) {

  .headerChangeOrgButton {
    margin-left: auto;

    &__button {
      color: $color-text-primary;
    }
  }
}

@include mq($until: tablet) {

  .headerChangeOrgButton {
    margin-top: 24px;

    &__label {
      color: $color-text-primary;
    }

    &__button {
      color: $color-text-accent;
    }
  }
}

.headerContentMobile {
  display: flex;
  justify-content: space-between;
  position: relative;
}
