@use "sass:math"; @import "abstract";
.FileActionPanel {
  display: flex;
  justify-content: space-between;

  &__name {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;

    & > span {
      color: $green;
    }
  }

  &__actions {
    align-items: center;
    color: $color-text-secondary;
    display: flex;
    gap: 16px;

    &-item:hover {
      color: $green;
    }
  }
}
