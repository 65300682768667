@use "sass:math"; @import "abstract";
.progress-bar {
  align-items: center;
  background-color: $lightGray;
  border-radius: 16px;
  display: flex;
  height: 16px;
  position: relative;

  &__indicator {
    background-color: $green;
    border-radius: 16px;
    display: inline-block;
    height: 16px;
    transition: 300ms linear;
  }

  &__value {
    font-weight: 600;
    position: absolute;
    right: 48%;
  }
}
