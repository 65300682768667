@use "sass:math"; @import "abstract";
.page-loader {
  animation: page-loader infinite 1500ms $acceleration;
  background-image: linear-gradient(
    90deg,
    transparent 25%,
    $green 50%,
    transparent 75%
  );
  background-size: 200% 100%;
  height: 4px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  will-change: background-position;
  z-index: 10001;
}

@keyframes page-loader {

  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -150% 0;
  }
}
