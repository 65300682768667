@use "sass:math"; @import "abstract";
.regionChooserTop {
  @include mq($from: tablet) {
    margin-right: 8px;
  }

  .userRegionSelectPickerDesktop {

    &__button {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include mq($from: desktop) {
        max-width: 335px;
      }

      @include mq($from: tablet, $until: desktop) {
        max-width: 320px;
      }
    }

    &:hover {
      background-color: $color-control-bg-secondary-inverted;

      .userRegionSelectPickerDesktop__label,
      .userRegionSelectPickerDesktop__button {
        color: $color-text-accent;
      }
    }
  }
}
