@use "sass:math"; @import "abstract";
.additional-menu-links {
  display: flex;

  @include mq($from: tablet, $until: desktop) {
    margin-top: 62px;
  }

  @include mq($until: tablet) {
    margin-top: 42px;
  }
}

.additional-menu-link {
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 4px 8px;
  transition: background-color 200ms $sharp;
  white-space: nowrap;

  @include mq($from: desktop) {
    color: $white;
    margin-right: 8px;
  }

  @include mq($until: desktop) {
    color: $black;

    &:not(:last-child) {
      margin-right: 32px;
    }
  }

  &__icon {
    margin-right: 8px;
  }

  &:hover {
    background-color: $color-control-bg-secondary-inverted;

    .additional-menu-link__icon path {
      stroke: $green;
    }
  }
}
