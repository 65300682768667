@use "sass:math"; @import "abstract";
.pagination-dots {
  display: flex;
  justify-content: center;
  text-align: center;

  &__dot {
    background-color: var(--active-dot-style, $black);
    border-radius: 10px;
    display: inline-block;
    height: var(--dot-size);
    width: var(--dot-size);

    &:not(&_active) {
      opacity: .2;
    }

    &_active {
      width: var(--active-dot-width);
    }
  }
}

.pagination-dots__dot+ .pagination-dots__dot {
  margin-left: var(--dot-size);
}
