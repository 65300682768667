@use "sass:math"; @import "abstract";
.offerPriceWithDiscount {

  &__discount {
    align-items: center;
    display: flex;
    margin-bottom: 8px;
  }

  &__discountPrice {
    color: $paleBlack;
    display: inline-flex;
    font-size: 12px;
    margin-right: 8px;
    position: relative;

    &::after {
      background-color: $warningColor;
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: 10px;
      width: 100%;
    }
  }

  &__chip {
    font-size: 14px;
    font-weight: bold;
    height: 24px;
    padding: 1px 6px 2px;
  }
}

@include mq($until: tablet) {

  .offerPriceWithDiscount {

    &__chip {
      font-size: 12px;
      height: 16px;
      padding: 1px 4px 2px;
    }

    &__discountPrice {

      &::after {
        top: 8px
      }
    }
  }
}
