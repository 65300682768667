@use "sass:math"; @import "abstract";
.journal-page {

  .journalSearchInput {
    margin-bottom: 32px;
    margin-top: 16px;
  }
}

.journal-block-swipe-sides {
  overflow-x:hidden;
}

@include mq($until: tablet) {

  .journal-page {

    .journalSearchInput {

      .search-input-actions__search {
        font-size: 14px;
        padding: 6px 16px 7px;
        width: 91px;
      }
    }
  }
}
