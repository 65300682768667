@use "sass:math"; @import "abstract";
.user-avatar {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;

  &__image {
    border-radius: 100%;
    flex-shrink: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &_with-patch {

    &::after {
      background-color: var(--patch-background);
      bottom: 0;
      content: '';
      height: 15%;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}
