@use "sass:math"; @import "abstract";
.user-accreditation-resubmission-banner {
  background-color: $lightGray;
  width: 100%;
  
  @include mq($from: desktop) {
    padding: rem(44) rem(66);
  }

  @include mq($from: tablet, $until: desktop) {
    margin-left: -38px;
    margin-right: -38px;
    padding: 44px 38px;
    width: calc(100% + 76px);
  }

  @include mq($until: tablet) {
    display: grid;
    grid-gap: mRem(24) mRem(8);
    grid-template-columns: mRem(16) 1fr;
    margin-bottom: mRem(32) !important;
    margin-left: mRem(-24);
    padding: mRem(24) mRem(24) mRem(32);
    width: calc(100% + #{mRem(48)});
  }

  &__button {
    @include mq($from: tablet, $until: desktop) {
      flex-shrink: 0;
      width: 208px;
    }

    @include mq($until: tablet) {
      grid-column: span 2;
      margin-right: auto;
    }
  }

  &__icon {
    color: $red
  }
}

.user-accreditation-resubmission-banner-documents {
  @include mq($from: tablet, $until: desktop) {
    padding-left: 10px;
  }

  @include mq($from: tablet) {
    padding-right: rem(40);
  }
}
