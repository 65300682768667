@use "sass:math"; @import "abstract";
.agricultural-product-categories-tree {
  position: relative;

  &_root {
    padding-left: 40px;
    padding-top: 6px;
  }

  &__icon {
    left: 0;
    position: absolute;
    top: 0;
  }
}

.agricultural-product-categories-tree-category {
  @include mqProp('grid-gap', 8px, 16px);

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__accordion {

    .accordion-item__heading {
      padding-left: 8px;
    }

    .accordion-item__icon {
      top: 0;
    }

    .accordion-item-header {
      padding-left: 6px
    }

    .accordion-item__header {

      > *:first-child {
        padding-left: 20px;
      }
    }
  }

  &__link {
    align-items: center;
    color: $black !important;
    display: flex;
    grid-gap: 8px;
    transition: color 200ms $sharp;

    &_active {
      cursor: default;
    }

    &:not(&_active):hover {
      color: $green !important;
    }
  }

  &__children {
    padding-left: 8px;
  }
}
