@use "sass:math"; @import "abstract";
.accreditationDocumentTitle {

  &__wrapper {
    background-color: $white;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    padding: 40px 40px 24px;
    row-gap: 8px;
  }
}

@include mq($until: tablet) {

  .accreditationDocumentTitle {

    &__wrapper {
      padding: 32px 16px 16px;
    }
  }
}
