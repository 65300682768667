@use "sass:math"; @import "abstract";
.closable-message {
  align-items: center;
  background-color: var(--closable-message-background, $lightGray);
  display: flex;
  justify-content: center;
  position: relative;

  @include mq($from: tablet) {
    padding: rem(24);
  }

  @include mq($until: tablet) {
    padding: mRem(16);
  }

  &__close-button {
    line-height: 100%;
    position: absolute;
    right: rem(10);
    top: rem(10);
  }
}
