@use "sass:math"; @import "abstract";
.app-mobile-auth-button {
  bottom: 0;
  left: 24px;
  position: fixed;
  right: 24px;
  transition: 300ms ease-in-out;
  transition-property: transform, z-index;
  width: calc(100% - 48px);
  z-index: 2008;

  &_down, &_up {
    transform: translateY(-76px);

    &.app-mobile-auth-button_with-cookie-banner {
      transform: translateY(-194px);
    }

    &.app-mobile-auth-button_safari {
      transform: translateY(-92px);

      &.app-mobile-auth-button_with-cookie-banner {
        transform: translateY(-232px) ;
      }
    }
  }

  &_throttle {
    transform: translateY(100%);
    z-index: -1;
  }
}
