@use "sass:math"; @import "abstract";
.offer-price {

  &_old {
    position: relative;

    &::before {
      @include mqProp('width', 120px, 90px);
      @include mqProp('transform', rotate(-21deg), rotate(-24deg));

      background-color: $warningColor;
      bottom: 4px;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      transform-origin: 0 0;
      z-index: 3;
    }

    .offer-price-unit,
    .offer-price-package {
      color: $paleBlack;
    }
  }
}


.offer-price-package {
  display: flex;
  position: relative;

  &_empty {
    color: $paleBlack;
  }
}

.offer-price-unit {
  display: flex;
  position: relative;
  z-index: 2;

  &__contract-info, &__price-info {
    font-family: $headingFontFamily;
  }

  &__hide-line {
    background-color: $lightGray;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.42);
    height: 8px;
    left: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-2.83deg);
    width: 68px;
  }

  &__measure {
    @include mq($until: tablet) {
      margin-top: 3px;
    }
  }
}
