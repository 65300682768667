@use "sass:math"; @import "abstract";
.auth-registration-document {
  @include mq($from: desktop) {
    margin-bottom: 24px;
  }

  @include mq($from: tablet, $until: desktop) {
    margin-bottom: 24px;
  }

  &__text {

    button {
      display: inline-block;
      min-height: 0;
      min-width: 0;
      padding: 0;
    }
  }
}
