@use "sass:math"; @import "abstract";
.collapsing-block {

  &__button {
    @include mqProp('padding', 24px, $mobilePageWrapperPadding);

    align-items: center;
    background-color: $white;
    color: $black;
    display: flex;
    justify-content: space-between;
    min-height: mRem(70);
    width: 100%;

    span {
      align-items: center;
      background-color: $lightGray;
      border-radius: 50%;
      color: $black;
      display: flex;
      flex-shrink: 0;
      height: mRem(37);
      justify-content: center;
      position: relative;
      transition: 200ms ease-out;
      transition-property: background-color, color;
      width: mRem(37);
      will-change: background-color, color;
    }

    &-white {

      span {
        background-color: $color-base-primary;
      }
    }

    span::before {
      content: '';
    }


    span::before {
      border-bottom: 1px solid $black;
      border-right: 1px solid $black;
      height: mRem(10);
      position: absolute;
      transform: rotate(45deg) translate(-2px, -1px);
      transition: transform 0.2s ease-out;
      width: mRem(10);
      will-change: transform, border-color;
    }

    &_active span::before {
      border-color: $white;
      transform: rotate(225deg) translate(-2px, -2px);
    }

    &_active span {
      background-color: $green;
    }
  }

  &__button-left {
    align-items: center;
    background-color: $white;
    color: $black;
    display: flex;
    min-height: mRem(40);
    padding: mRem(24);
    width: 100%;

    span {
      align-items: center;
      background-color: $lightGray;
      border-radius: 50%;
      color: $black;
      display: flex;
      flex-shrink: 0;
      height: mRem(37);
      justify-content: center;
      margin-right: 24px;
      position: relative;
      transition: 200ms ease-out;
      transition-property: background-color, color;
      width: mRem(37);
      will-change: background-color, color;
    }



    span::before {
      content: '';
    }


    span::before {
      border-bottom: 1px solid $black;
      border-right: 1px solid $black;
      height: mRem(10);
      position: absolute;
      transform: rotate(45deg) translate(-2px, -1px);
      transition: transform 0.2s ease-out;
      width: mRem(10);
      will-change: transform, border-color;
    }

    &_active span::before {
      border-color: $white;
      transform: rotate(225deg) translate(-2px, -2px);
    }

    &_active span {
      background-color: $green;
    }
  }
}

.collapsing-block-content {
  max-height: 0;
  overflow: hidden;
  padding-left: mRem(24);
  padding-right: mRem(51);
  transition: max-height 0.2s ease-out;
  width: 100%;
  will-change: max-height;

  &--expanded {
    max-height: none;
  }
}
