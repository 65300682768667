@use "sass:math"; @import "abstract";
.appFixedOverlayContainer {
  align-items: flex-end;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 40px;
  row-gap: 20px;
  z-index: 9999;
}


@include mq($until: desktop) {

  .appFixedOverlayContainer {
    bottom: 20px;
    right: 20px;
    transform: translateY(-60px);
  }
}

@include mq($until: tablet) {

  .appFixedOverlayContainer {
    bottom: 16px;
    left: 16px;
    right: 16px;
  }
}
