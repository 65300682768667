@use "sass:math"; @import "abstract";
.wide-article-preview-link {
  @include mqProp('padding', 48px 32px 32px, $mobilePageWrapperPadding);

  height: 100%;
  width: 100%;
}

.wide-article-preview {
  @include mqProp('width', calc(66.66% - 6px), calc(100% + 32px), calc(100% - 6px));

  background-color: $white;
  flex-shrink: 0;

  @include mq($until: tablet) {
    margin-left: -$mobilePageWrapperPadding;
  }

  &__image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;

    @include mq($until: tablet) {
      padding-bottom: math.div(206%, 327) * 100;
    }

    @include mq($from: tablet) {
      height: rem(318);
      margin-right: 32px;
      padding-bottom: 50%;
      width: 50%;
    }
  }
}

.wide-article-preview-link {
  display: flex;

  @include mq($until: tablet) {
    flex-direction: column;
  }
}

.wide-article-preview-content {
  flex-grow: 1;
}
