@use "sass:math"; @import "abstract";
.rs-picker-date-menu {
  z-index: 2005;
}

.date-picker-input-container {
  position: relative;
  z-index: 22;
}

.input-wrapper.input-wrapper {

  .rs-picker-daterange-calendar-group {
    height: auto;
  }

  .rs-calendar-table-cell-selected-end {

    &::before {
      border-bottom-right-radius: 32px !important;
      border-top-right-radius: 32px !important;
    }

    &:nth-child(2), &[title*="01,"] {

      &::before {
        width: 100%;
      }
    }
  }

  .rs-calendar-table-cell {

    &:not(.rs-calendar-table-cell-in-range) {

      & + .rs-calendar-table-cell-selected-end {

        &::before {
          display: none;
        }
      }
    }

    &.rs-calendar-table-cell-in-range {

      & + .rs-calendar-table-cell-selected-start {

        &::before {
          @include scaledRem('height', 32, 29);

          background-color: #F3F3F3;
          margin-top: 0;
          transform: translateX(-50%);
          width: 200%;
        }
      }
    }
  }

  .rs-calendar-table-cell-in-range {

    &[title*="31,"], &[title*="30,"], &[title*="29,"] {

      &::before {
        border-bottom-right-radius: 32px;
        border-top-right-radius: 32px;
      }
    }
  }

  .rs-calendar-table-cell-in-range, .rs-calendar-table-cell-selected-end {

    &[title*="01,"] {

      &::before {
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 32px;
        border-top-right-radius: 0;
        transform: none;
      }

      &:first-child {

        &::before {
          border-radius: 32px;
          width: 100%;
        }
      }
    }

    &:nth-child(2) {

      &::before {
        border-bottom-left-radius: 32px;
        border-top-left-radius: 32px;
      }
    }

    &:first-child {

      &::before {
        border-bottom-right-radius: 32px;
        border-top-right-radius: 32px;
      }
    }

    &:nth-child(2) {

      &::before {
        transform: none;
      }
    }

    &::before {
      @include scaledRem('height', 32, 29);

      background-color: $lightGray;
      margin-top: 0;
      transform: translateX(-50%);
      width: 200%;
    }
  }

  .rs-calendar-table-cell-selected-start.rs-calendar-table-cell-selected-end {

    &::before {
      display: none;
    }
  }

  .rs-calendar.rs-calendar {
    height: auto;
    padding: 0;
    width: auto;
  }

  .rs-picker-menu {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  }

  .rs-calendar-table-row {
    display: flex;
    justify-content: space-between;

    &:nth-child(2) > .rs-calendar-table-cell-un-same-month:first-child {
      opacity: 0;
      z-index: -1;
    }
  }

  .rs-calendar-table-cell-un-same-month {

    .rs-calendar-table-cell-content {
      opacity: .4;
    }
  }

  .rs-calendar-view {
    @include mqProp('padding', #{0 rem(25) rem(31) rem(20)}, #{0 mRem(27) mRem(25) mRem(14)});
  }

  .rs-calendar-table-cell.rs-calendar-table-cell {
    @include scaledRem('height', 32, 29);
    @include scaledRem('width', 32, 29);
    @include scaledRem('margin-bottom', 4, 8);

    display: flex;
  }

  .rs-calendar-table-cell-content.rs-calendar-table-cell-content {
    @include scaledRem('height', 32, 29);
    @include scaledRem('width', 32, 29);
    @include scaledRem('font-size', 14, 12);

    align-items: center;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .rs-calendar-table-cell-disabled {

    .rs-calendar-table-cell-content {
      opacity: .4;
    }
  }

  .rs-calendar-table-row:not(.rs-calendar-table-header-row) {

    &:last-child {
      height: auto;

      .rs-calendar-table-cell-un-same-month {
        display: none !important;
      }
    }

    .rs-calendar-table-cell-selected.rs-calendar-table-cell-disabled {

      .rs-calendar-table-cell-content {
        background-color: transparent;
        color: rgba($black, .4);
      }
    }

    .rs-calendar-table-cell-selected:not(.rs-calendar-table-cell-disabled), .rs-calendar-table-cell-selected-start {

      .rs-calendar-table-cell-content {
        background-color: $green !important;
        color: $white !important;
        opacity: 1;
        text-decoration: none;
      }
    }

    .rs-calendar-table-cell-content {
      border-radius: 100%;
      color: $black;
      transition: 200ms $sharp;
      transition-property: background-color, color;
      will-change: background-color, color;
    }

    .rs-calendar-table-cell {
      display: flex;
      padding: 0;
      width: auto;

      &:first-child {
        @include mqProp('transform', translateY(calc(-100% - #{rem(4)})), translateY(calc(-100% - #{mRem(8)})));

        order: 1;

        &[title*="01,"], &[title*="01,"] ~ .rs-calendar-table-cell {
          @include scaledRem('margin-top', 36);
        }
      }

      &:not(.rs-calendar-table-cell-disabled):not(.rs-calendar-table-cell-selected) {

        .rs-calendar-table-cell-content {

          &:hover, &:focus {
            background-color: transparent;
            color: $green;
          }
        }
      }
    }
  }

  .rs-picker-toolbar {
    display: none;
  }

  .rs-calendar-table-header-row {
    @include mq($until: tablet) {
      margin-bottom: -1px;
    }

    .rs-calendar-table-cell-content.rs-calendar-table-cell-content {
      @include scaledRem('font-size', 12);

      color: rgba($black, .3);
      font-weight: 600;
      padding: 0;
      text-transform: uppercase;
    }

    > .rs-calendar-table-cell {

      &:first-child {
        order: 1;
      }
    }
  }

  .rs-picker-block {
    min-width: 100%;
  }

  .rs-calendar-header.rs-calendar-header {
    @include mqProp('padding', #{rem(26) rem(23) rem(18) rem(20)}, #{mRem(26) mRem(8) mRem(15) mRem(6)});

    width: auto;

    > div {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .rs-picker-daterange-header {
    display: none;
  }

  .rs-calendar-header-title {
    @include scaledRem('font-size', 16, 14);
    @include mqProp('line-height', em(24, 16), em(19.6, 14));

    color: $mainTextColor;
    flex-grow: 1;
    font-family: $headingFontFamily;
    font-weight: 700;
    padding: 0;

    @include mq($until: tablet) {
      font-family: $fontFamilyBase;
    }

    &:hover, &:focus {
      background-color: transparent !important;
    }
  }

  .rs-picker-has-value {

    .rs-picker-toggle-caret {
      filter: none;
      opacity: 1 !important;
    }
  }

  .rs-calendar-month-dropdown-year.rs-calendar-month-dropdown-year-active {
    color: $green;
  }


  .rs-calendar-month-dropdown-cell {

    &.rs-calendar-month-dropdown-cell-active {

      .rs-calendar-month-dropdown-cell-content {
        background-color: $green;
        border-radius: 50%;
      }
    }
  }

  .rs-calendar-month-dropdown-row {
    @include scaledRem('padding-top', 16);
    @include scaledRem('padding-bottom', 16);
  }

  .rs-calendar-header-backward, .rs-calendar-header-forward {
    @include scaledRem('width', 32);
    @include scaledRem('height', 32);
    @include scaledRem('background-size', 26, 16);

    background-position: center;
    background-repeat: no-repeat;
    padding: 0;

    &::before {
      content: none;
    }
  }

  .rs-calendar-header-backward {
    background-image: url("/assets/icons/flat/arrow-left.svg");
  }

  .rs-calendar-header-forward {
    background-image: url("/assets/icons/flat/arrow-right.svg");
  }

  .rs-picker-default {

    .rs-picker-toggle.rs-btn {
      @include mqProp('padding-left', 24px, 14px);

      align-items: center;
      background-color: $lightGray !important;
      border: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      left: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      @include mq($from: mobile) {
        padding-right: 24px;
      }

      @include mq($until: mobile) {
        padding-right: 12px;
      }

      .rs-picker-toggle-placeholder {
        @include scaledRem('font-size', 14);

        color: $paleBlack;
        flex-grow: 1;
        font-weight: 400;
      }

      .rs-picker-toggle-value {
        color: $green !important;
        flex-grow: 1;
        transition: color .2s $sharp;
        will-change: color;
      }

      .rs-picker-toggle-caret {
        @include scaledRem('width', 24, 16);
        @include scaledRem('height', 24, 16);

        background: url("/assets/icons/flat/calendar.svg") no-repeat 50% 50%;
        background-size: cover;
        bottom: auto;
        flex-shrink: 0;
        left: auto;
        margin-left: auto;
        opacity: .5;
        order: 2;
        position: static;
        right: auto;
        top: auto;
        transform: none;
        transition: 250ms $sharp;
        transition-property: filter, opacity;
        will-change: filter, opacity;

        &::before {
          content: '' !important;
        }
      }

      .rs-picker-toggle-clean {
        bottom: auto;
        left: auto;
        margin-left: .25rem;
        order: 3;
        position: static;
        right: auto;
        top: auto;
        transform: none;
      }
    }
  }

  .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn, .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
    @include scaledRem('padding-right', 16);
  }


  .rs-picker-select-menu-items {
    margin-bottom: 0;
  }

  .rs-picker-menu {
    @include scaledRem('max-width', 381);

    border-radius: 0;
    padding-top: 0 !important;
    top: 3rem !important;
    z-index: 2004;
  }
}

.input-wrapper__input_color-white {

  .rs-picker-toggle-placeholder, .rs-picker-toggle-value {
    color: $white !important;
  }

  .rs-picker-toggle-caret, .rs-picker-toggle-clean {
    filter: brightness(10);
  }
}

