@use "sass:math"; @import "abstract";
.hanging-marker {
  @include scaledRem('font-size', 14);

  align-items: center;
  border-radius: 100%;
  color: $black !important;
  display: flex;
  font-weight: 600;
  justify-content: center;
  position: absolute;

  @include mq($from: tablet) {
    height: 26px;
    right: -3px;
    top: -3px;
    width: 26px;
  }

  @include mq($until: tablet) {
    height: 23px;
    right: -8px;
    top: -8px;
    width: 23px;
  }

  &_yellow {
    background-color: #EAF84A;
  }

  &_filter {
    background-color: $green;
    color: white !important;
    position: static;
  }
}
