@use "sass:math"; @import "abstract";
.faqComponent {
  padding-top: 80px;

  &__title {
    margin-bottom: 40px;
  }

  &__block {
    display: grid;
    gap: 4px;
  }

  &__item {
    padding: 22px;
    transition: color 200ms $sharp;
    will-change: color;

    &:hover {
      color: $green;
    }
  }

  &__answer {
    display: grid;
    gap: 24px;
    padding: 24px 0 26px;

    ul {
      display: grid;
      gap: 24px;
    }

    li {
      padding-left: 42px;
      position: relative;

      &::before {
        background-image: url('/assets/icons/check-green.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 12px;
        left: 6px;
        position: absolute;
        top: 6px;
        width: 16px;
      }
    }
  }
}

@include mq($until: desktop) {

  .faqComponent {
    padding-top: 40px;

    &__item {
      padding: 22px 22px 24px 24px;
    }
  }
}

@include mq($until: tablet) {

  .faqComponent {

    &__title {
      margin-bottom: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }

    &__item {
      padding: 16px 24px;
    }
  }
}

.collapsing-block__button-left span {
  width: rem(40);
}
