@use "sass:math"; @import "abstract";
.mobileNavBar {
  background-color: $black;
  bottom: 0;
  color: $white;
  display: flex;
  height: 60px;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1001;
}

.mobileNavBarLink {
  align-items: center;
  color: $white;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &--active {
    color: $green;

    &:focus {
      color: $green;
    }
  }

  &__text {
    margin-top: 2px;
  }

  &__counter {
    align-items: center;
    background: $red;
    border: 2px solid $black;
    border-radius: 10px;
    color: $white;
    display: flex;
    font-weight: bold;
    height: 20px;
    justify-content: center;
    left: calc(50% - 5px);
    padding: 0 4px;
    position: absolute;
    top: 6px
  }
}

.noWrap {
  white-space: nowrap;
}
