@use "sass:math"; @import "abstract";
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.3;
  position: relative;
  z-index: 1;

  @include mq($until: tablet) {
    margin-top: -16px;
  }

  > .breadcrumb {
    margin-bottom: 16px;
  }
}

.breadcrumb_back {
  color: $black !important;
}

.breadcrumb {
  color: $paleBlack;
  display: inline-flex;
  position: relative;

  &:first-child, &_link.breadcrumb_link.breadcrumb_link {
    @include mq($from: tablet) {
      color: $paleBlack !important;
    }
  }

  &:last-child {
    color: $black !important;
  }

  &_back.breadcrumb_back.breadcrumb_back {
    align-items: center;
    display: inline-flex;
    margin-right: auto;

    @include mq($until: tablet) {
      color: $mainTextColor;
      margin-bottom: 24px;
    }

    &::before {
      @include scaledRem('width', 16, 15);
      @include scaledRem('height', 16);
      @include mqProp('margin-right', 16px, 10px, 16px);

      background-image: url('/assets/icons/flat/open.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';

      @include mq($until: tablet) {
        background-image: url('/assets/icons/flat/arrow-left.svg');
      }
    }
  }

  &_back.breadcrumb_back {
    color: $mainTextColor, !important;
    font-weight: normal;
    opacity: 1;

    @include mq($until: tablet) {
      font-size: mRem(14);
      line-height: mRem(17);
    }
  }

  &:not(:last-child):not(.breadcrumb_back) {

    &::after {
      color: $black;
      content: '•';
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
}


[data-page="/agroservices/calc"] {
  @include mq($until: tablet) {
    padding-top: 16px;
  }

  .breadcrumbs {
    @include mq($from: tablet) {
      margin-bottom: 8px;
    }
  }
}
