.agroservice-card-background-image {
  @include mqProp('object-position', center, -3px ​19px);

  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: 300ms $sharp;
  transition-property: transform, opacity, filter;
  width: 100%;
  will-change: transform, opacity, filter;
  z-index: 1;
}

.agroservice-card-wrapper {
  overflow: hidden;
  position: relative;

  &:hover {

    .agroservice-card-background-image {
      transform: scale(1.15);
    }
  }
}

.agroservice-card-item-wrapper {
  z-index: 2;

  &.with-blurred-bottom-block::before {
    z-index:-1;
  }

  &__logo {
    @include scaledRem('max-height', 27, 23, 23);

    flex-shrink: 0;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}

.agroservice-small-price {
  @include mqProp('transform', translateY(-5px), translateY(-6px));
  @include mqProp('margin-left', 24px, 16px, 16px);

  margin-top: auto;
  white-space: nowrap;

  &__initial {
    display: inline-block;
    opacity: .5;
    position: relative;

    &::after {
      @include scaledRem('height', 2);

      background-color: $white;
      border-radius: 5px;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(-15deg);
      transform-origin: 100% 13px;
      width: 100%;
    }
  }

  &__period {
    opacity: .7;

    @include mq($from: tablet, $until: desktop) {
      margin-right: 12px;
    }
  }

  &__actual {
    @include mq($from: tablet, $until: desktop) {
      order: -1;
    }
  }
}

.storybook-agroservice-card-wrapper {
  @include mqProp('width', 1300px, 360px, 800px);

  align-items: center;
  color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
}

.storybook-agroservice-card {

  &__general--size {
    @include mqProp('width', calc(100%/3 - 8px/3), 100%, 100%);
    @include mqProp('height', 471px, 480px, 460px);
  }

  &__small--size {
    @include mqProp('height', 240px, 224px, 286px);
    @include mqProp('width', calc(100%/3 - 8px/3), 100%, calc(50% - 2px));
  }

  &__wide--size {
    @include mqProp('height', 266px, 224px, 306px);
  }
}
