@use "sass:math"; @import "abstract";
.client-card {
  align-items: center;
  background-color: #fff;
  display: grid;

  @include mq($from: tablet) {
    grid-column-gap: 24px;
    grid-template-columns: 28.9% 21.4% 13.8% 7.1% 12.4% 2.6%;
    padding: 32px 32px 32px 40px;
  }

  @include mq($until: tablet) {
    grid-template-columns: 50% 8.2% 36.5% 5%;
    grid-template-rows: repeat(2, 1fr);
    padding: 23px 24px;
  }

  &__title {
    padding-right: 18px;
    position: relative;
    word-break: break-word;
  }

  &__mark.client-card__mark {
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__last-request {
    @include mq($until: tablet) {
      grid-column: 3;
      grid-row: 1 / span 2;
    }
  }

  &__button-icon {
    color: $green;
    max-height: rem(24);

    @include mq($until: tablet) {
      grid-column: 4;
      grid-row: 1 / span 2;
    }
  }

  &__inn {
    @include mq($until: tablet) {
      grid-row: 2;
    }
  }
}
