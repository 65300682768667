@use "sass:math"; @import "abstract";
.telegramSubscribeOverlayBanner {

  &--entering {
    animation: entering 1s forwards;
  }

  &--exiting {
    animation: exiting 1s forwards;
  }

  &__container {
    background: linear-gradient(90deg, #00C4FF, #49CE47);
    border-radius: 12px;
    box-shadow: 0 0 20px 0 rgba(46, 32, 50, 0.1);
    height: 248px;
    opacity: 0;
    position: relative;
    width: 240px;
  }

  &__header {
    color: $color-text-inverted;
    max-width: 90%
  }

  &__text {
    color: #B9BEC7;
    max-width: 90%;
  }

  &__icon {
    color: $color-text-secondary;
    opacity: 0.7;
    position: absolute;
    right: 12px;
    top: 12px;
    transition: opacity 0.3s ease, transform 0.3s ease;


    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:active {
      transform: scale(1.2);
    }
  }

  &__backgroundImage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__innerContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32px 20px 20px;
    position: relative;
    width: 100%;
  }

  &__tgIcon {
    background-image: url('/assets/images/index/telegramBanner/tg-icon-web.png');
    background-repeat: no-repeat;
    background-size: contain;
    filter: drop-shadow(0 0 20px rgba(46, 32, 50, 0.1));
    height: 120px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateY(-57%);
    width: 110px;
  }

  &__actionButton {
    margin-top: auto;
    width: 100%;
  }
}


@include mq($until: tablet) {

  .telegramSubscribeOverlayBanner {


    &__container {
      align-self: center;
      background: linear-gradient(90deg, #00C4FF, #49CE47);
      border-radius: 12px;
      height: 209px;
      width: 312px;
    }


    &__tgIcon {
      background-image: url('/assets/images/index/telegramBanner/tg-icon-web.png');
      height: 80px;
      width: 74px;
    }
  }
}


@keyframes entering {

  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exiting {

  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
