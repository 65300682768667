@use "sass:math"; @import "abstract";
$closeIcoSize: 2.143rem;

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.react-hint__content {
  background: $hintBgColor;
  border-radius: 5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  color: $mainTextColor;
  padding: 0.65rem;
  size: 1rem;
}

.react-hint--top::after {
  border-top-color: $hintBgColor;
}

.react-hint--left::after {
  border-left-color: $hintBgColor;
}

.react-hint--right::after {
  border-right-color: $hintBgColor;
}

.react-hint--bottom::after {
  border-bottom-color: $hintBgColor;
}

main {
  background-color: $lightGray;
  flex-grow: 1;
  position: relative;

  @include mq($from: tablet) {
    padding-top: 24px;
  }

  @include mq($from: tablet) {
    padding-bottom: 96px;
  }

  @include mq($until: tablet) {
    padding-bottom: 56px;
    padding-top: 32px;
  }

  &[data-page='/'],
  &[data-page*='/journal'], {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.rs-picker-menu .rs-picker-search-bar::after {
  top: 21px !important;
}

.app-page-loader {
  @include scaledRem('top', 120px, 52px);
}

.signUkepErrorModal {

  &__button {
    margin-bottom: 16px;
    margin-top: 16px;
  }
}
