@use "sass:math"; @import "abstract";
.quantity-picker {
  align-items: stretch;
  background-color: $lightGray;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: opacity .2s ease-out;

  @include mq($from: tablet) {
    height: rem(40);
    width: rem(137);
  }

  @include mq($until: tablet) {
    height: mRem(37);
    width: mRem(110);

    > :first-child {
      padding-right: 8px;
    }

    > :last-child {
      padding-left: 8px;
    }
  }

  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &--cursor {
    pointer-events: none
  }

  &__button {
    display: flex;
  }

  &__value {
    background: 0;
    border: 0;
    max-width: rem(40);
    text-align: center;

    &:focus {
      outline: 0;
    }
  }

  > * {
    align-items: center;
    display: flex;
    justify-content: center;

    &:first-child {
      padding-left: 12px;
    }

    &:last-child {
      padding-right: 12px;
    }
  }
}
