@use "sass:math"; @import "abstract";
.harvestSaleMessageCard {

  &__comment {
    background-color: $lightGray;
    padding: 8px 16px;
  }

  &__triangle {
    border: 8px solid transparent;
    border-bottom: 8px solid $lightGray;
    margin-top: -8px;
    width: 16px;
  }

  &__triangle--left {
    margin-left: 32px;
  }

  &__triangle--right {
    margin-left: auto;
    margin-right: 32px;
  }
}
