@use "sass:math"; @import "abstract";
.order-details-notification-container {
  background-color: $white;

  @include mq($from: desktop) {
    padding: 0 45px 55px 35px;
  }

  @include mq($from: tablet, $until: desktop) {
    padding: 0 38px 49px;
  }
}

.order-details-notification {
  background-color: $green;

  @include mq($from: desktop) {
    padding: 40px 80px;
  }

  @include mq($from: tablet, $until: desktop) {
    padding: 36px 80px;
  }

  @include mq($until: tablet) {
    padding: 32px $mobilePageWrapperPadding;
  }

  &__image {
    @include mq($from: tablet) {
      margin-left: rem(-14);
      margin-right: rem(28);
      margin-top: rem(-8);
    }

    @include mq($until: tablet) {
      flex-shrink: 0;
      margin-right: mRem(22);
    }
  }

  &__title {
    @include mq($from: tablet) {
      margin-bottom: rem(32);
    }

    @include mq($until: tablet) {
      font-family: $fontFamilyBase;
      font-size: mRem(14);
      font-weight: 400;
      line-height: 1.43;
    }
  }
}

.order-details-notification-banner-text {
  @include mq($until: tablet) {
    margin-bottom: mRem(17)
  }
}

.order-details-notification-actions {
  @include mq($until: tablet) {
    display: flex;
    flex-direction: column;
    row-gap: mRem(16);
  }
}
