@use "sass:math"; @import "abstract";
.documents {
  position: relative;

  @include mq($until: tablet) {
    min-width: 100%;
  }

  &__otherDocumentsGroup {
    margin-top: 4px;
  }

  &__paginationWrapper {
    margin-left: 24px;
    margin-top: 32px;
  }
}

.documents-upload-button-container {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: row;

  @include mq($from: desktop) {
    padding: 32px 40px;
  }

  @include mq($from: tablet, $until: desktop) {
    padding: 32px 38px;
  }

  @include mq($from: tablet) {
    margin-bottom: 4px;
  }

  @include mq($until: tablet) {
    margin-bottom: 2px;
    padding: 32px;
  }

  &__infoIconWrapper {
    margin-left: 16px;
  }
}

.document-statuses {
  @include mq($from: desktop) {
    grid-template-columns: 63% 37%;
  }

  @include mq($from: tablet, $until: desktop) {
    grid-column-gap: 38px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: tablet) {
    display: grid;
  }

  @include mq($until: tablet) {
    display: flex;

    .status-marker + .status-marker {
      margin-left: 23px;
    }
  }
}

@include mq($until: tablet) {

  .documents {

    &__otherDocumentsGroup {
      margin-top: 2px;
    }
  }
}
