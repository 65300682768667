@use "sass:math"; @import "abstract";
.agricultural-product-available-volume {
  display: inline-flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 11px;
  }

  &__label {
    opacity: 0.4;
  }

  &__with-icon {
    align-items: center;
    color: $green;
    display: flex;
  }
}
