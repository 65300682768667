@use "sass:math"; @import "abstract";
.ui-steps-container {
  background-color: $white;
  overflow: hidden;

  @include mq($from: tablet) {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  @include mq($until: tablet) {
    padding-bottom: 56px;
    padding-top: 56px;
  }
}

.ui-steps {
  display: grid;

  @include mq($from: tablet) {
    grid-gap: 32px 25px;
    margin-left: -16px;
    padding-right: 36px;
    padding-top: 36px;
  }

  @include mq($until: tablet) {
    grid-row-gap: 43px;
    grid-template-columns: 54.7% 1fr;
    margin-bottom: 0;
    margin-left: -16px;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    transform: translateY(2px);
    width: calc(100% + 48px);
  }

  &_grid-col-3 {
    @include mq($from: tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &_grid-col-4 {
    @include mq($from: tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.ui-step-stroke {
  border-top: 1px dashed $black;
  opacity: 0.4;
  position: absolute;
  transition: width 250ms linear;
  will-change: width;

  @include mq($from: tablet) {
    top: 25px;
    width: calc(100% + #{rem(59)});
  }

  @include mq($until: tablet) {
    top: 19px;
    width: calc(100% + #{rem(40)});
  }
}

.ui-step {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;

  @for $i from 1 through 8 {

    &:nth-child(#{$i}) {

      .ui-step-stroke {
        transition-delay: $i * 250ms;
      }
    }
  }

  &[fade-in-up='false'] {

    .ui-step-stroke {
      width: 0 !important;
    }
  }

  &_last {

    .ui-step__step-label {
      background-color: $green;
      color: $lightGray;
    }

    .ui-step__title {
      color: $green;
    }

    .ui-step__title-vertical-mobile {
      color: $green;
    }

    .ui-step__description-vertical-mobile {
      color: $green;
    }

    .ui-step-stroke {
      display: none;
    }

    .ui-step-stroke-vertical-mobile {
      display: none;
    }
  }

  &_grid-col-3 {

    &:nth-child(3n) {
      @include mq($from: tablet) {

        .ui-step-stroke {
          width: 100vw;
        }
      }
    }

    &:nth-child(3n + 4) {
      @include mq($from: tablet) {

        .ui-step-stroke {
          left: calc(-100vw + 110%);
          width: 100vw;
        }
      }
    }
  }

  &_grid-col-4 {

    &:nth-child(4n) {
      @include mq($from: tablet) {

        .ui-step-stroke {
          width: 100vw;
        }
      }
    }

    &:nth-child(4n + 5) {

      .ui-step-stroke {
        @include mq($from: tablet) {
          left: calc(-100vw + 110%);
          width: 100vw;
        }
      }
    }
  }

  &:nth-child(2n + 3) {
    @include mq($until: tablet) {

      .ui-step-stroke {
        left: -1rem;
      }
    }
  }

  &:last-child {
    @include mq($until: tablet) {
      grid-column: 1 / 3;
    }
  }

  &:nth-last-child(2) {
    @include mq($until: tablet) {
      grid-column-start: 2;
    }

    .ui-step-stroke {
      @include mq($until: tablet) {
        display: block;
        left: -134%;
        width: 140%;
      }
    }
  }

  &_grid-col-4.ui-step_last {

    .ui-step-stroke {
      display: none;
    }
  }

  &__step-label {
    align-items: center;
    border-radius: 100%;
    color: $green;
    display: flex;
    height: rem(48);
    justify-content: center;
    position: inherit;
    width: rem(48);
    z-index: 2;

    @include mq($from: tablet) {
      margin-bottom: 24px;
      padding-top: 4px;
    }

    @include mq($until: tablet) {
      font-family: $fontFamilyBase;
      margin-bottom: 19.2px;
    }
  }

  &__title {
    padding-left: 16px;

    @include mq($until: tablet) {
      padding-right: 12px;
    }
  }

  &__title-vertical-mobile {
    @include mq($until: tablet) {
      margin-top: -50px;
      padding-bottom: 16px;
      padding-left: 56px;
    }
  }

  &__description-vertical-mobile {
    @include mq($until: tablet) {
      margin-top: -12px;
      padding-bottom: 16px;
      padding-left: 56px;
    }
  }

  &__button-vertical-mobile {
    @include mq($until: tablet) {
      padding-bottom: 10px;
    }
  }

  &__button {
    @include mq($from: tablet) {
      margin-left: 15px;
    }

    @include mq($until: tablet) {
      grid-column: span 2;
    }
  }

  &_button-position-end.ui-step_grid-col-3 {
    @include mq($from: tablet) {
      grid-column: 3/4;
    }
  }

  &_button-position-end.ui-step_grid-col-4 {
    @include mq($from: tablet) {
      grid-column: 4/5;
    }
  }
}

.ui-steps-sign {
  @include mqProp('margin-left', 0, -16px);
  @include mqProp('margin-right', 0, -16px);

  background-color: $green;
  color: $white;
  display: flex;
  justify-content: center;

  @include mq($from: tablet) {
    padding: 32px;
  }

  @include mq($until: tablet) {
    margin-bottom: -56px;
    margin-top: 56px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-top: 32px;
  }

  &__content {
    max-width: rem(740);

    @include mq($from: tablet) {
      margin-left: 26px;
      padding-top: 16px;
    }

    @include mq($until: tablet) {
      font-family: $fontFamilyBase;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.43;
      padding: 0 16px;
    }
  }
}

.ui-step-submit-button-wrapper {
  display: inline-flex;
}


.ui-steps-vertical-mobile {
  @include mq($until: tablet) {
    grid-row-gap: 43px;
    grid-template-columns: 54.7% 1fr;
    margin-bottom: 0;
    margin-left: -24px;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    transform: translateY(2px);
    width: calc(100% + 48px);
  }
}

.ui-step-stroke-vertical-mobile {
  @include mq($until: tablet) {
    border-left: 1px dashed $black;
    height: calc(100% + #{rem(40)});
    left: 19px;
    opacity: 0.4;
    position: absolute;
    transition: height 250ms linear;
    will-change: height;
  }
}
