@use "sass:math"; @import "abstract";
.buyer-black-label {
  display: flex;
  
  &_absolute {
    position: absolute;
    
    @include mq($from: tablet) {
      right: 40px;
      top: 40px;
    }

    @include mq($until: tablet) {
      right: 24px;
      top: 24px;
    }
  }
}
