@use "sass:math"; @import "abstract";
.articlePreviewBanner {

  &__image {
    @include scaledRem('height', 420, 345, 360);
    @include scaledRem('min-height', 420, 345, 360);

    display: block;
    margin: auto;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  &__title {
    @include mqProp('bottom', 35px, 44px, 52px);
    @include mqProp('left', 63px, 16px);

    color: $white;
    position: absolute;
    transition: color 200ms $sharp;
    will-change: color;
    z-index: 2;

    @include mq($from: desktop) {
      width: 100%;
    }

    @include mq($until: desktop) {
      right: 16px;
    }

    @include mq($from: tablet) {
      max-width: rem(896px);
    }
  }

  &__link {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;

    &:hover,
    &:focus {

      .article-preview-banner__title {
        color: $green;
      }
    }

    &::after {
      @include scaledRem('height', 185, 169, 204);

      background: linear-gradient(180deg, rgba(46, 32, 50, 0) 0.04%, rgba(46, 32, 50, 0.7) 57.29%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      z-index: 1;
    }
  }
}
