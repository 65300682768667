@use "sass:math"; @import "abstract";
.interactive-step {
  align-items: flex-start;
  display: flex;
  
  @include mq($from: tablet) {
    flex-direction: column;
  }

  &:last-child {
    @include mq($from: tablet) {
      width: 33.33%
    }
  }

  &__label {
    align-items: center;
    background-color: $lightGray;
    border-radius: 100%;
    color: $green;
    display: flex;
    flex-shrink: 0;
    height: 48px;
    justify-content: center;
    width: 48px;
    z-index: 2;

    @include mq($from: tablet) {
      margin-bottom: 24px;
      padding-top: 4px;
    }

    @include mq($until: tablet) {
      font-size: mRem(16);
      margin-right: 16px;
      padding-top: 5px;

      h5 {
        font-family: $headingFontFamily;
      }
    }

    &_active {
      background-color: $green;
      color: $white;
    }
  }

  &__description {
    @include mq($from: desktop) {
      max-width: rem(390);
    }

    @include mq($from: tablet, $until: desktop) {
      max-width: rem(210);
    }
    
    @include mq($until: tablet) {
      max-width: mRem(235);
    }
  }

  &_completed {
    opacity: .5;
  }
}
