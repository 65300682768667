@use "sass:math"; @import "abstract";

.order-footer-cell {
  @include mq($from: tablet) {
    grid-row: 4;
  }

  &_status {
    @include mq($until: tablet) {
      grid-row: 2;
    }
  }
}

.order {
  background-color: #fff;
  display: grid;

  @include mq($from: tablet) {
    align-items: baseline;
    grid-column-gap: 54px;
    grid-template-columns: 1fr rem(272);
    padding: 40px 42px 52px 40px;
  }

  @include mq($until: tablet) {
    grid-template-columns: 100%;
    padding: 31px $mobilePageWrapperPadding 23px;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &__button {
    width: 100%;
  }

  .productsContainer {
    @include mq($from: tablet) {
      max-width: rem(296);
    }

    p {
      margin: 0;
    }

    .orderProduct {
      margin-top: 4px;
    }
  }

  &::after {
    @include mqProp('margin-top', 32px, 16px);

    background-color: $lightGray;
    content: "";
    grid-row: 3;
    height: 2px;
    margin-bottom: 24px;

    @include mq($from: tablet) {
      grid-column: 1 / span 2;
    }
  }

  &__status {
    align-self: center;

    .comment-text {
      @include mq($until: tablet) {
        margin-left: 28px;
        margin-top: 8px;
      }
    }
  }
}

.order-address {
  @include mq($from: tablet) {
    max-width: rem(216);
  }

  @include mq($until: tablet) {
    grid-row: 2;
  }

  &__text {
    @include mqProp('margin-top', 6px, 4px);
  }
}
