@use "sass:math"; @import "abstract";
.error-modal-content {

  &__text {
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__link {
    text-decoration: none;

    &_type_phone {
      font-weight: 600;
    }
  }
}

