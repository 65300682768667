@use "sass:math"; @import "abstract";
.search-input-with-suggestions {
  position: relative;
  width: 100%;
  z-index: 2003;

  .autosuggest-container__loader {
    @include mq($from: tablet) {
      left: 0;
    }

    @include mq($until: tablet) {
      left: auto;
      right: 98px;
    }
  }

  &_in-modal {
    z-index: 2004;
  }

  &:not(&_has-actions) {

    .autosuggest-container__loader {
      @include mq($until: tablet) {
        right: 8px;
      }
    }
  }

  .base-autosuggest {

    &__suggestion {
      color: $black;
      padding: 0;

      &--highlighted {
        color: $black;
      }
    }

    &__suggestions-container {
      max-height: 60vh;

      @include mq($from: desktop) {
        max-height: 70vh;
      }
    }
  }
}

.search-input-with-suggestions-modal {
  background-color: $white;
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  padding: 16px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4000;

  &__close-button {
    margin: 8px 16px auto 0;
  }

  &__content {
    width: 100%;
  }

  .base-autosuggest__suggestion {
    @include mq($until: tablet) {
      padding-bottom: 4px;
      padding-left: 0;
      padding-right: 16px;
      padding-top: 12px;
    }
  }

  .base-autosuggest__suggestions-container {
    transform: translateY(5px);

    @include mq($from: tablet) {
      max-height: max(calc(100vh - #{rem(480)}), 80vh);
    }

    &--open {
      @include mq($until: tablet) {
        box-shadow: none;
        margin-left: -40px;
        min-height: 100vh;
        width: calc(100% + 40px);
      }
    }
  }
}

.search-input-actions {
  @include mqProp('top', 4px, 3px);

  display: flex;
  position: absolute;
  right: 4px;
  z-index: 21;

  button:not(:first-child) {
    margin-left: 10px;
  }

  &__reset {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &__search {
    @include mqProp('padding', 9px 24px 10px, 6px 16px 7px);
    @include scaledRem('min-height', 40, 34);

    @include mq($until: tablet) {
      min-width: mRem(75);
      width: mRem(75);
    }
  }

  &_with-actions {

    .input-wrapper__input {
      @include mqProp('padding-right', 140px);
    }
  }
}

.search-suggestion {
  @include mqProp('padding', 16px 36px 16px 70px, 0 4px 0 0);

  display: flex;
  justify-content: space-between;

  &__body {
    max-width: rem(500);

    @include mq($until: tablet) {
      padding-right: 16px;
    }
  }

  &__image {
    @include scaledRem('height', 64, 32);
    @include scaledRem('max-width', 210, 110);

    margin-bottom: auto;
    margin-top: auto;
    object-fit: cover;
  }

  &__divider {
    @include mq($until: tablet) {
      margin-bottom: -4px;
      margin-top: 12px;
    }
  }
}

.search-suggestion-category {
  margin-top: 6px;

  &__item:not(:last-child) {

    &::after {
      color: $paleBlack;
      content: '•';
      display: inline-block;
      margin: 0 8px;
    }
  }
}
