@use "sass:math"; @import "abstract";
.orderDetailsNotificationButton {
  padding-left: 22px;
  padding-right: 22px;
}

.orderDetailsNotificationButtonContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
}

@include mq($until: tablet) {

  .orderDetailsNotificationButtonContainer {
    align-items: center;
    flex-direction: column-reverse;
    row-gap: 8px;
  }

  .orderDetailsNotificationButton {
    white-space: nowrap;
  }
}

@include mq($from: tablet) {

  .orderDetailsNotificationButtonContainer {
    column-gap: 16px;
  }
}
