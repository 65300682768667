@use "sass:math"; @import "abstract";
.faq-article {
  @include mqProp('padding', 40px 56px, 40px 24px, 40px);

  position: relative;

  @include mq($from: tablet) {
    grid-column: span 2;
  }

  &__title {
    margin-bottom: 17px;
  }
}

.faq-article-container {
  @include mqProp('grid-template-columns', repeat(2, 1fr), 100%);

  align-self: flex-start;
  display: grid;
  grid-gap: 1px;
}

.faq-article-tags {
  margin-bottom: -12px;
  margin-right: -12px;
  margin-top: 17px;
  width: calc(100% + 12px);

  &__tag {
    margin-bottom: 12px;
    margin-right: 12px;
  }
}
