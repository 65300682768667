@use "sass:math"; @import "abstract";
.user-accreditation-banner {
  background-color: $green;
  color: $white;
  width: 100%;

  @include mq($from: desktop) {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 64px;
    min-height: rem(144);
    padding: rem(32) rem(66);
  }

  @include mq($from: tablet, $until: desktop) {
    display: grid;
    grid-column-gap: 36px;
    grid-row-gap: 16px;
    grid-template-columns: rem(78) 1fr;
    padding: 32px 40px 40px 60px;
    width: 100%;
  }

  @include mq($until: tablet) {
    display: grid;
    grid-gap: mRem(16);
    grid-template-columns: mRem(56) 1fr;
    margin-left: mRem(-24);
    padding: mRem(32) mRem(24);
    width: calc(100% + #{mRem(48)});
  }

  &__heading {
    @include mq($from: tablet, $until: desktop) {
      margin-top: 16px;
    }

    @include mq($until: tablet) {
      margin-bottom: mRem(10);
    }
  }

  &__title {
    flex-shrink: 0;

    @include mq($from: desktop) {
      margin-left: rem(26);
      max-width: rem(390);
    }

    @include mq($from: tablet, $until: desktop) {
      margin-left: 0;
    }

    @include mq($until: tablet) {
      margin-left: mRem(6);
    }

    &_without-btn {
      max-width: unset;
      width: calc(100% - 112px);
    }
  }

  &__text {
    color: rgba($white, .7);
  }

  &__button {
    @include mq($from: tablet) {
      width: rem(208)
    }

    @include mq($from: tablet, $until: desktop) {
      grid-column: 2;
    }

    @include mq($until: tablet) {
      grid-column: span 2;
    }
  }

  &_without-image {
    @include mq($from: tablet, $until: desktop) {
      grid-template-columns: 1fr;
    }

    .user-accreditation-banner__button {
      @include mq($from: tablet, $until: desktop) {
        grid-column: 1;
      }
    }
  }
}

.user-completed-accreditation-banner {

  .user-accreditation-banner__text {
    @include mq($from: tablet) {
      white-space: nowrap;
    }
  }

  .user-accreditation-banner__button {
    @include mq($from: desktop) {
      align-self: flex-start;
      margin-top: rem(5);
    }

    @include mq($from: tablet, $until: desktop) {
      margin-top: 16px;
    }
  }
}


.userSignAccreditationBanner {
  @include mq($from: desktop) {
    margin-bottom: 64px;
  }

  @include mq($until: desktop) {
    margin-bottom: 24px;
  }

  .user-accreditation-banner__button {
    align-self: flex-start;
    margin-top: 16px;
  }
}
