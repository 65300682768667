@use "sass:math"; @import "abstract";
.modal {
  align-items: flex-start;
  background-color: rgba($black, 0.5);
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  position: fixed;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  z-index: 2006;

  &.fading {

    &-enter .modal-content {
      opacity: 0;
      transform: translateY(-15px);
    }

    &-enter-active,
    &-exit .modal-content {
      opacity: 1;
      transform: translateY(0);
    }

    &-exit-active .modal-content {
      opacity: 0;
      transform: translateY(-15px);
    }
  }
}

.modal-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2030;
}

.modal-content-wrapper {
  height: auto;
  margin: auto;
  z-index: 2032;
}

.modal-content-portal {
  @include mq($until: tablet) {
    width: mRem(312);
  }
}

.modal-content {
  background-color: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  transition-duration: 150ms;
  transition-property: opacity, transform;
  transition-timing-function: $sharp;
  will-change: opacity, transform;

  @include mq($from: desktop) {
    min-width: rem(414);
    padding: 40px;
  }

  @include mq($from: tablet, $until: desktop) {
    min-width: rem(312);
    padding: 48px 40px;
  }

  @include mq($until: tablet) {
    padding: 48px 24px 24px;
    width: mRem(312);
  }

  h3:not(.with-custom-offsets) {
    margin-bottom: 24px;
  }

  &__headerIcon {
    margin-bottom: 24px;
  }

  &__close-button {
    @include mqProp('top', 32px, 24px);
    @include mqProp('right', 32px, 24px);

    position: absolute;
    z-index: 3000;

    svg {
      margin-right: 0;
      transition: fill 0.2s $sharp;
      will-change: fill;
    }

    &:hover {

      svg {
        fill: rgba($mainTextColor, 0.8);
      }
    }
  }

  &__close-label {
    color: #81919d;
    font-size: 1rem;
    margin-left: -50%;
    margin-top: 0.703rem;
    position: absolute;
    text-align: center;
    top: 100%;
    width: 200%;
  }

  &_draggable {
    transition-property: opacity;
    will-change: opacity;
  }
}
