@use "sass:math"; @import "abstract";
.mobile-navigation-container {
  @include mq($until: desktop) {
    background-color: $white;
    opacity: 0;
    position: absolute;
    top: calc(100% + 2px);
    transform: translateY(-100%);
    transition: 250ms $sharp;
    transition-property: transform, opacity;
    z-index: -1;
  }

  @include mq($from: tablet, $until: desktop) {
    left: rem(265);
    min-width: rem(502);
    padding: 62px;
  }

  @include mq($until: tablet) {
    box-shadow: $stickedBoxShadowBottom;
    left: 0;
    padding: $mobilePageWrapperPadding;
    right: 0;
  }

  &_active {
    opacity: 1;
    transform: none;
  }
}

.header-menu-button {
  @include mq($until: tablet) {
    color: $color-text-secondary;
  }

  &:hover {
    color: $green;
  }
}
