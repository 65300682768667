@use "sass:math"; @import "abstract";
.autosuggest-container {
  position: relative;

  &__loader {
    @include mqProp('top', 9px, 4px);
    @include mqProp('left', -4px, -6px);
    @include mqProp('transform', scale(.4), scale(.3));

    height: 28px;
    width: 28px;
  }

  .base-autosuggest__input--focused {
    outline: none;
  }

  .base-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .base-autosuggest__suggestions-container {
    background-color: $white;
    max-height: rem(286);
    opacity: 0;
    overflow: auto;
    transform: translateY(-5px);
    transition: 250ms $sharp;
    transition-property: transform, opacity, z-index;
    will-change: transform, opacity, z-index;
  }

  .base-autosuggest__suggestions-container--open {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    opacity: 1;
    position: absolute;
    top: rem(49);
    transform: none;
    width: 100%;
    z-index: 500;
  }

  .base-autosuggest__suggestions-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .base-autosuggest__suggestion {
    cursor: pointer;
    padding: rem(24);
    transform: 250ms $sharp;
    transition-property: background-color, color;
    will-change: background-color, color;
  }

  .base-autosuggest__suggestion--highlighted {
    background-color: $lightGray;
    color: $green;
  }
}
