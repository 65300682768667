@use "sass:math"; @import "abstract";
.toggle-block {
  display: flex;

  &:not(.row_column) {
    align-items: center;
    justify-content: flex-start;
  }

  &.row_column {

    .rs-btn-toggle {
      margin-left: -4px;
    }
  }

  &__label {
    align-items: center;
    display: flex;
    gap: 4px;

    &_left {
      margin-right: 16px;
    }

    &_top {
      margin-bottom: 16px;
    }

    &_right {
      margin-left: 16px;
      order: 1;
    }

    &_bottom {
      margin-top: 16px;
      order: 1;
    }
  }

  &__tooltipContent {
    align-items: center;
    color: $color-text-secondary;
    display: flex
  }

  &__button {

    &_left {
      margin-left: auto;
    }
  }

  .rs-btn-toggle, .rs-btn-toggle:hover {
    @include scaledRem('width', 48);
    @include scaledRem('height', 24);

    background-color: var(--switcher-style, $white);
    left: 4px;
  }

  .rs-btn-toggle::after {
    @include scaledRem('width', 16);
    @include scaledRem('height', 16);

    background-color: var(--switcher-thumb-style, $lightGreen);
    top: 50%;
    transform: translateY(-50%);
  }

  .rs-btn-toggle-checked, .rs-btn-toggle-checked:hover, .rs-btn-toggle-checked:active {
    background-color: $lightGreen;

    &::after {
      background-color: $green;
    }
  }
}

