@use "sass:math"; @import "abstract";
.exact-farming-text-phone-section {
  background-color: $white;
  background-repeat: no-repeat;

  @at-root .app_with-webp & {
    background-image: url('/assets/images/agroservices/exactFarmingPhone.webp');
  }

  @at-root .app_no-webp & {
    background-image: url('/assets/images/agroservices/exactFarmingPhone.png');
  }

  @include mq($from: desktop) {
    background-position: calc(50% + 355px) 96px;
    background-size: 400px;
    padding-bottom: 96px;
    padding-top: 96px;
  }

  @include mq($from: tablet, $until: desktop) {
    background-position: calc(50%) calc(100% + 209px);
    padding-bottom: 369px;
    padding-top: 68px;
  }

  @include mq($until: tablet) {
    background-position: calc(50%) calc(100% + 182px);
    background-size: 311px;
    padding-bottom: 331px;
    padding-top: 64px;
  }

  &__title {
    @include mq($from: desktop) {
      width: 738px;
    }
  }

  &__text {
    @include mq($from: desktop) {
      margin-bottom: 69px;
      min-height: 200px;
      width: 632px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 66px;
    }

    @include mq($until: tablet) {
      margin-bottom: 34px;
    }
  }

  &__button-wrapper {
    @include mq($from: tablet, $until: desktop) {
      display: flex;
      justify-content: center;
    }
  }

  &__button {
    @include mq($until: tablet) {
      width: 168px;
    }
  }
}
