@use "sass:math"; @import "abstract";
.tabs {

  &__content {
    display: flex;
    flex-wrap: wrap;

    > * {
      @include mq($from: tablet) {
        width: 100%;
      }

      @include mq($until: tablet) {
        width: 100%;
      }
    }
  }
}

.tab-list {
  background: $white;
  display: flex;

  @include mq($from: tablet) {
    margin-bottom: 4px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include mq($until: desktop) {
    overflow-x: auto;
  }

  @include mq($until: tablet) {
    margin-bottom: 2px;
    padding-left: $mobilePageWrapperPadding;
    padding-right: $mobilePageWrapperPadding;
    padding-top: 36px;
    position: relative;
    width: 100%;
  }
}

.tab-list-shadow {
  @include mqProp('width', 32);

  flex-shrink: 0;
  position: sticky;
  z-index: 50;

  &_left {
    @include mqProp('left', -24);
    @include mqProp('margin-left', -32);

    background: linear-gradient(to right, $white, rgba($white, 0.8),  rgba($white, 0));
  }

  &_right {
    @include mqProp('right', -24);
    @include mqProp('margin-right', -32);

    background: linear-gradient(to left, $white, rgba($white, 0.8), rgba($white, 0));
  }
}

