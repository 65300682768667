@use "sass:math"; @import "abstract";
.consultingForm {

  &__heading {
    margin-bottom: 16px;
  }

  &__headingTitle {
    margin-bottom: 8px;
  }

  &__inputs {
    display: grid;
    gap: 16px;
  }

  &__error {
    color: $color-text-error;
    margin-bottom: 32px;
  }

  &__footer {
    padding-top: 24px;
    text-align: center;
  }

  &__submit {
    margin-top: 24px;
  }
}

@include mq($until: tablet) {

  .consultingForm {

    &__error {
      margin-bottom: 24px;
    }
  }
}
