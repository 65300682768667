@use "sass:math"; @import "abstract";
.historyItem {
  @include mq($from: tablet) {
    @include block(rem(120), 40, 318, 40, 40);
  }

  @include mq($until: tablet) {
    background-color: $white;
    padding: mRem(40);
    padding-right: mRem(50);
  }

  + .historyItem {
    @include mq($from: tablet) {
      margin-top: rem(4);
    }

    @include mq($until: tablet) {
      margin-top: mRem(2);
    }
  }

  &__text {
    font-size: rem(16);
    line-height: 1.2;
    margin-bottom: rem(8);
  }

  &__date {
    color: $color-text-secondary;
    font-size: rem(12);
  }

  &__details {
    margin-top: rem(24);

    .initiator {
      font-size: rem(14);
      font-weight: 700;
      margin-bottom: rem(8);
    }

    .amount {
      font-size: rem(14);
      margin-top: rem(6);

      &__label {
        color: $color-text-secondary;
      }

      &__value {
        color: $color-text-primary;
      }
    }
  }
}
