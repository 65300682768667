@use "sass:math"; @import "abstract";
.upload-photo {

  &__button {
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    padding: 0;
    position: relative;
  } 

  &__input {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
  }
}
