@use "sass:math"; @import "abstract";
.product-supplier {

  &__logo {
    flex-shrink: 0;

    @include mq($from: tablet) {
      width: rem(57);
    }


    @include mq($until: tablet) {
      width: mRem(61)
    }

    &:not(&_inline) {
      margin-bottom: rem(2);
    }

    &_inline {
      @include mq($from: tablet) {
        margin-right: rem(8);
      }

      @include mq($until: tablet) {
        margin-right: mRem(8);
      }
    }
  }
}
