@use "sass:math"; @import "abstract";
.overlay {
  background: transparentize($black, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2004;
}
