@use "sass:math"; @import "abstract";
.faq-categories {
  display: grid;
  grid-gap: 48px 32px;
  grid-template-columns: rem(394) 1fr;

  .disabled-accordion-item {
    cursor: pointer;
  }

  .faq-tree-categories {
    grid-column-start: 1;
  }

  .page-notice-text {
    padding-top: 0;
  }
}

.faq-list {
  @include mqProp('grid-row-gap', 4px, 2px);

  display: grid;
  grid-template-columns: 100%;
}

.faq-list-item {
  @include mqProp('padding', 24px 38px, 16px 24px);

  background-color: $white;

  &_with-arrow {
    @include mqProp('background-position', calc(100% - 38px) 50%, calc(100% - 24px) 50%);
    @include scaledRem('background-size', 24, 16);

    background-image: url('/assets/icons/flat/arrow-right.svg');
    background-repeat: no-repeat;
  }
}

.faq-categories-search {
  margin-left: auto;
  max-width: rem(737);
}

.faq-banner {
  @include mqProp("margin-top", 96px, 56px, 96px);
}
