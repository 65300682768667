@use "sass:math"; @import "abstract";
.productsContainer {
  @include mq($from: tablet) {
    max-width: rem(264);
  }
}

.orderProduct {
  border-collapse: collapse;
  display: table;

  @include mq($from: tablet) {
    margin-bottom: 3px;
  }

  &__name {
    display: table-cell;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::after {
      @include scaledRem('bottom', 5, 5);

      border-bottom: 1px dashed;
      content: '';
      height: 0;
      margin-left: 6px;
      opacity: 0.2;
      position: absolute;
      width: 100%;
    }
  }

  &__weight {
    display: table-cell;
    padding-left: 6px;
    vertical-align: bottom;
    white-space: nowrap;
  }
}
