@use "sass:math"; @import "abstract";
.simple-dropdown {
  position: relative;
  
  &__label {
    opacity: .5;
  }

  &__icon {
    will-change: transform, color;
    transition: {
      duration: 250ms;
      property: transform, color;
      timing-function: $sharp;
    };

    svg {
      @include mq($until: tablet) {
        height: mRem(24);
        width: mRem(24);
      }
    }

    &_active {
      transform: rotate(180deg)
    }
  }
}

.simple-dropdown-items {
  @include scaledRem('width', 239);
  @include scaledRem('max-height', 200);
  @include mqProp('grid-gap', 8px, 16px);

  background-color: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  left: 0;
  margin-top: 16px;
  overflow: auto;
  padding: 24px 40px 24px 24px;
  position: absolute;
  top: 100%;
}

.simple-dropdown-item {
  animation: fade-in-up 250ms ease forwards;
  color: $black;
  opacity: 0;
  transform: translateY(50%);
  transition: color 250ms $sharp;
  white-space: normal;
  will-change: transform, opacity, color;

  &:hover, &:focus {
    color: $green;
  }

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      animation-delay: #{250 + ($i - 1) * 80}ms;
    }
  }
}
