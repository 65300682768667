@use "sass:math"; @import "abstract";
.explanation-block {
  display: flex;
  flex-direction: row;

  &__icon {
    border-radius: 50%;
    color: $green;
    flex-grow: 0;
    flex-shrink: 0;
    height: 24px;
    margin-right: 10px;
    text-align: center;
    width: 24px;
  }

  &__title {
    color: $green;
  }

  &__description {
    margin-top: 4px;
  }
}
