@use "sass:math"; @import "abstract";
.journalArticles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__title {
    margin-bottom: 32px;
    width: 100%;
  }

  &__item {
    margin-bottom: 16px;
  }
}

@include mq($until: tablet) {

  .journalArticles {

    &__title {
      margin-bottom: 24px;
    }
  }
}

@include mq($from: tablet) {

  .journalArticles {
    max-width: rem($pageContentMaxWidth + 6);
    width: calc(100% + 6px);

    &__item {
      margin-right: 6px;
    }
  }
}
