@use "sass:math"; @import "abstract";
.indexInputsCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 16px;
  position: relative;
  width: 100%;
  z-index: 2;

  &__overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &__imageBlock {
    align-items: center;
    background-color: $color-base-secondary;
    border-radius: 4px;
    display: flex;
    height: 140px;
    justify-content: center;
    overflow: hidden;
    padding: 8px;
    position: relative;
    width: 100%;
  }

  &__productImage {
    border-radius: 4px;
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  &__labels {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    left: 8px;
    position: absolute;
    top: 8px;
    z-index: 2;
  }

  &__priceBlock {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }

  &__loginToSeePriceLink {
    order: 2;
    z-index: 2;
  }

  &__discountPrice {
    order: 2;
  }

  &__mainPrice {
    order: 1;
  }

  &__buttonRow {
    z-index: 2;
  }
}

@include mq($until: tablet) {

  .indexInputsCard {

    &__imageBlock {
      height: 124px;
    }
  }
}
