@use "sass:math"; @import "abstract";
.catalog-product-card-labeled-info {
  @include mq($from: tablet) {
    display: inline-flex;
    flex-direction: column;
  }

  @include mq($until: tablet) {
    align-items: flex-end;
    display: flex;
    width: 100%;
  }

  & + & {
    @include mq($until: tablet) {
      margin-top: 8px;
    }
  }

  &__label {
    opacity: 0.4;

    @include mq($from: desktop) {
      margin-bottom: 2px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 1px;
    }
  }
}
