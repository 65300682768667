@use "sass:math"; @import "abstract";
.accreditationUkepRequestBanner {

  &__wrapper {
    background-color: $white;
    padding: 16px 40px;
  }

  &__card {
    align-items: center;
    background-color: $lightGreen;
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: 1fr auto;
    padding: 32px 40px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

@include mq($until: tablet) {

  .accreditationUkepRequestBanner {

    &__wrapper {
      padding: 8px 16px;
    }

    &__card {
      padding: 16px;
    }
  }
}
