@use "sass:math"; @import "abstract";
.article-preview-link {
  @include mqProp('padding', 32px, $mobilePageWrapperPadding);

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.article-preview {
  @include mqProp('width', calc(33.33% - 6px), calc(100% + 32px), calc(50% - 6px));
  @include mqProp('min-height', rem(480), auto, rem(387));

  background-color: $white;
  flex-shrink: 0;

  @include mq($until: tablet) {
    margin-left: -$mobilePageWrapperPadding;
  }

  &__image {
    @include scaledRem('min-height', 256, 201, 203);

    background-color: $lightGray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    width: 100%;

    @include mq($from: tablet, $until: desktop) {
      padding-bottom: math.div(203, 284) * 100%;
    }

    @include mq($until: tablet) {
      padding-bottom: math.div(206, 327) * 100%;
    }
  }
}
