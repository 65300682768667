@use "sass:math"; @import "abstract";
.upload-file-button {
  position: relative;

  &_with-single-uploaded-file {

    .rs-uploader-file-items {
      margin: 0;
    }
  }

  .rs-uploader-trigger input[type='file'] {
    width: 100%;
  }

  .rs-uploader-text .rs-uploader-trigger-btn.rs-uploader-trigger-btn {
  
    > svg {
      margin-top: -2px;
    }
  }
  
  .rs-uploader-text .rs-uploader-file-item {
    align-items: center;
    display: flex;
    padding: 0;
    padding-bottom: 0;
  }
  
  .rs-uploader-text .rs-uploader-file-item-icon-wrapper {
    margin-right: 5px;
    position: static;
    width: auto;
  }
  
  .rs-uploader-text .rs-uploader-file-item-title {
    color: $green !important;
    display: block;
    overflow: hidden;
  }
  
  .rs-uploader-text .rs-uploader-file-item-panel {
    max-width: calc(100% - 33px);
  }
  
  .rs-uploader-file-item-status {
    display: none;
  }
  
  .rs-uploader-text .rs-uploader-file-item-btn-remove {
    position: static;
  }
}
