@use "sass:math"; @import "abstract";
.popover {
  position: relative;

  & > button {
    display: flex;
    font-weight: 600;
    gap:16px;
  }

  &__section {
    background-color: $color-base-primary;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 #2E20321A;
    padding: 16px;
    position: absolute;
    right: 0;
    top:40px;
    z-index: 100;
  }
}
