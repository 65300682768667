@use "sass:math"; @import "abstract";
.reviews {
  background-color: transparent;
  padding: 80px 0;

  &__title {
    margin-bottom: 40px;
  }

  &__slider {
    position: relative;
  }

  &__arrow {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 38%;
    z-index: 1;

    &--left {
      left: -32px;
    }

    &--right {
      right: -32px;
    }
  }

  .swiper-slide {
    height: auto;
    min-height: 268px;
  }
}

.reviewsSlide {
  background-color: $color-base-secondary;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;

  &__author {
    margin-bottom: 8px;
  }

  &__text {
    flex: 1;
  }

  &__notes {
    display: flex;
    gap: 48px;
  }
}

.reviewsNote {
  display: flex;
  gap: 8px;

  &__label {
    color: $color-text-tertiary;
  }

  &__icon {
    color: $color-base-accent;
  }
}

@include mq($until: tablet) {

  .reviews {

    &__arrow {

      &--left {
        left: -12px;
      }

      &--right {
        right: -12px;
      }
    }
  }
}
