@use "sass:math"; @import "abstract";
.temporary-unavailable-tag {
  background: rgba(46, 32, 50, 0.5);
  border-radius: 8px;
  box-shadow: 0 0 1px 1px rgba($white, .3);
  color: $white;
  display: inline-block;
  font-size: rem(14);
  padding: 3px 12px 4px;
  white-space: nowrap;
}
