@use "sass:math"; @import "abstract";
.customRadio {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    cursor: pointer;
    display: none;
  }

  &__checkmark {
    background-color: $color-control-bg-secondary;
    border: 1px solid $color-control-border-primary;
    border-radius: 50%;
    flex-shrink: 0;
    height: 24px;
    left: 0;
    margin-right: 16px;
    top: 0;
    width: 24px;
    will-change: background-color, border;
    transition: {
      duration: .2s;
      property: background-color, border;
      timing-function: $sharp;
    };

    &_medium {
      height: 16px;
      margin-right: 8px;
      width: 16px;
    }

    &_white {
      background-color: $color-control-bg-primary;
    }

    &_error {
      border-color: $color-control-border-error;
    }

    &:hover {
      border-color: $color-control-border-accent-hover;
    }
  }

  input:checked ~ &__checkmark {
    background-color: $color-control-bg-primary;
    border-color: $color-control-border-accent;
    border-width: 7px;

    &_medium {
      border-width: 4px;
    }
  }

  input:disabled ~ &__checkmark {
    opacity: 0.3;
    pointer-events: none;
  }

  &__label {
    flex: 1;
  }
}
