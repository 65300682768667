@use "sass:math"; @import "abstract";
.journalShortNews {
  flex-wrap: wrap;

  &__main {
    background-color: #f3f3f3;
    position: relative;
    width: 420px;
  }

  &__header {
    margin-bottom: 16px;
  }

  &__mainLink {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 32px;
  }

  &__mainDatePublished {
    color: $color-text-secondary;
  }

  &__mainTitle {
    margin-bottom: 6px;
  }

  &__content {
    column-gap: 48px;
    display: flex;
  }

  &__newsList {
    display: flex;
    flex-direction: column;
  }

  &__listItem {
    margin-bottom: 3px;
  }
}

.journal-short-news-header-with-rubric {
  @include mq($from: tablet, $until: desktop) {
    padding-top: 47px;
  }

  @include mq($until: tablet) {
    padding-top: 40px;
  }

  @include mq($until: mobile) {
    word-break: break-all;
  }
}

@include mq($until: desktop) {

  .journalShortNews {

    &__main {
      width: 100%;
    }

    &__mainTitle {
      margin-bottom: 10px;
    }

    &__content {
      flex-direction: column;
    }
  }
}

@include mq($until: tablet) {

  .journalShortNews {

    &__listItem {
      margin-bottom: 8px;
    }

    &__mainTitle {
      margin-bottom: 8px;
    }
  }
}
