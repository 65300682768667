@use "sass:math"; @import "abstract";
.journalTags {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: flex-start;
    padding-bottom: 24px;
    padding-top: 24px;
  }

  &__tag {
    white-space: nowrap;
  }
}
