@use "sass:math"; @import "abstract";
.changeOrgButton {
  align-items: center;
  column-gap: 4px;
  display: flex;
  font-size: 14px;

  &__organizationName {
    max-width: 177px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__button {
    align-items: center;
    color: $color-text-inverted;
    column-gap: 4px;
    display: flex;

    &:hover, &:focus {
      color: $color-text-accent;
    }
  }

  &__label {
    color: $color-text-tertiary;
  }
}
