@use "sass:math"; @import "abstract";
.status-comment {
  background-color: $lightGray;
  border-radius: 0 8px 8px;
  max-width: 250px;
  padding: 8px;
  white-space: break-spaces;

  @include mq($until: tablet) {
    max-width: 100%;
  }

  svg {
    height: 14px;
    margin-top: 2px;
    width: 14px;
  }
}
