@use "sass:math"; @import "abstract";
.appErrorProduction {
  margin: 0 auto;
  max-width: 300px;
  text-align: center;

  &__button {
    margin-top: 20px;
  }
}
