@use "sass:math"; @import "abstract";
.installmentCalculator {
  background: $color-base-primary;
  padding: 24px 40px 40px;

  &--landing {
    display: flex;
    gap: 64px;
    padding: 0;
    width: 100%;
  }

  &__title {
    padding-bottom: 24px;
  }

  &__sliders {
    display: grid;
    gap: 32px;
    grid-auto-flow: column;

    &--landing {
      display: flex;
      flex: 1;
      flex-direction: column;
      grid-auto-flow: unset;
    }
  }

  &__results {
    border-radius: 8px;
    box-shadow: 0 0 20px 0 $gray-100;
    margin-top: 40px;
    overflow: auto;
    text-align: center;
  }

  &__landingResults {
    width: 480px;
  }

  &__landingResultsBanner {
    background-color: $color-status-success-default-fill;
    background-image: url('/assets/images/installmentCalculator/landingResult.svg');
    background-position: bottom right;
    background-repeat: no-repeat;
    margin-top: 27px;
    padding: 16px 32px 24px;
  }

  &__landingResultsBannerInfo {
    display: flex;
    gap: 8px;
  }

  &__landingResultsTitle {
    color: $color-text-primary;
  }

  &__landingResultsSumm {
    font-family: Masiva;
  }

  &__landingResultsButton {
    margin-top: 20px;
  }

  &__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__detail {
    align-items: end;
    align-self: start;
    display: grid;
    gap: 16px;
    margin: 16px 0;
    padding: 16px;
  }

  &__logo {
    height: 32px;
  }

  &__logoContainer {
    display: flex;
    justify-content: center;
  }

  &__summaries {
    background: $color-status-success-default;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 12px 0;
  }

  &__calculation {
    height: 48px;
    position: relative;
  }

  &__detail:first-child,
  &__summary:first-child {
    border-right: 1px dashed $color-control-border-primary;
  }

  &__mdash {
    display: block;
    padding-top: 12px;
  }

  &__footnote {
    border-top: 1px solid $color-control-border-primary;
    color: $color-text-disabled;
    margin-top: 32px;
    padding: 16px 0 0 12px;

    &--landing {
      border: unset;
    }

    &::before {
      content: '*';
      margin-left: -12px;
      position: absolute;
    }
  }

  &__button {
    display: block;
    margin: 12px auto 4px;
  }

  &__priceRequestButton {
    display: block;
    margin: 32px auto 42px;
  }

  &--inactive {
    opacity: 0.5;
  }

  .range-slider-inputs__control {
    background: $color-base-secondary;
    border: 1px solid $color-control-border-primary;
  }
}

@include mq($until: tablet) {

  .installmentCalculator {
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    white-space: pre-line;

    &__sliders {
      grid-auto-flow: row;
    }

    &__logo {
      margin-bottom: 24px;
      zoom: 65%;
    }

    &__priceRequestButton {
      display: block;
      margin: 32px auto 40px;
    }
  }
}

@include mq($until: desktop) {

  .installmentCalculator {

    &--landing {
      gap: 24px;
    }

    &__landingResults {
      flex: 1;
      width: unset;
    }
  }
}

@include mq($until: tablet) {

  .installmentCalculator {

    &--landing {
      flex-direction: column;
      padding: 0;
    }

    &__landingResultsBanner {
      padding: 16px 16px 24px;
    }

    &__landingResultsBannerInfo {
      flex-direction: row;
    }

    &__priceRequestButton {
      display: block;
      margin: 32px auto;
    }
  }
}
