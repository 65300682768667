@use "sass:math"; @import "abstract";
@import '../styles';

.agroservice-small-card-item {
  @include mqProp('padding', 40px 52px 31px 48px, 32px $mobilePageWrapperPadding 24px $mobilePageWrapperPadding, 40px 42px 45px 41px);
  @include mqProp('padding-bottom', 30px, 24px);

  display: grid;
  height: 100%;
  z-index: 2;

  @include mq($until: tablet) {
    grid-template-rows: auto auto 1fr;
  }

  &.with-blurred-bottom-block::before {
    height: 100%;
  }

  &__text {
    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 28px;
    }
  }

  &__bottom {
    align-items: end;
  }
}

.agroservice-small-small {

  &__container-price {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
