@use "sass:math"; @import "abstract";
.agroserviceGeneralCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__logo {
    align-self: end;
    box-sizing: content-box;
    padding: 16px;
    width: 124px;
    z-index: 2;
  }

  &__item {
    padding: 40px;
  }

  &__title {
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 24px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    button {
      margin: 0;
    }
  }
}

@include mq($until: desktop) {

  .agroserviceGeneralCard {

    &__logo {
      width: 136px;
    }

    &__text {
      max-width: 400px;
    }
  }
}

@include mq($until: tablet) {

  .agroserviceGeneralCard {

    &__logo {
      width: 104px;
    }

    &__item {
      padding: 24px;
    }

    &__text {
      margin-bottom: 16px;
      max-width: initial;
    }
  }
}
