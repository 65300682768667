@use "sass:math"; @import "abstract";
.nav-list {
  @include mq($from: desktop) {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 32px;
    max-width: 1020px;
  }

  @include mq($from: tablet, $until: desktop) {
    grid-row-gap: 40px;
  }

  @include mq($until: desktop) {
    display: grid;
    grid-template-columns: 1fr;
  }

  @include mq($until: tablet) {
    grid-row-gap: 32px;
    margin-top: 42px;
  }

  @include mq($until: mobile) {
    padding-bottom: 8px;
  }
}

a.nav-list-item-link {

  &_active, &:hover {
    color: $green !important;
  }
}

.nav-list-item-link {
  align-items: center;
  color: $black;
  display: flex;
  white-space: nowrap;

  svg {
    @include mq($until: tablet) {
      margin-right: 8px;
    }
  }

  &__icon {
    display: flex;

    @include mq($from: desktop) {
      margin-right: 8px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-right: 7px;
    }

    @include mq($until: tablet) {
      margin-right: 8px;
    }
  }
}
