@use "sass:math"; @import "abstract";
.cultures {
  display: flex;
  flex-wrap: wrap;
}

.culture {
  align-items: center;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 35px;
  margin-right: 7px;
  margin-top: 8px;
  width: 35px;

  &__image {
    width: 100%;
  }

  &_others {
    box-shadow: none;
    font-weight: 600;
  }
}
