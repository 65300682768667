@use "sass:math"; @import "abstract";
.counterparty-info {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include mq($from: tablet) {
    padding: rem(24) rem(40);
  }

  @include mq($until: tablet) {
    flex-wrap: wrap;
    padding: mRem(24) mRem(24) mRem(0) mRem(24);
  }

  &__container {
    @include mq($until: tablet) {
      flex-basis: 100%;
    }
  }

  &__name {
    @include mq($until: tablet) {
      margin-top: mRem(6);
    }
  }
}

.counterparty-info__valid-to {
  @include mq($until: tablet) {
    letter-spacing: 0.008em;
    margin-top: 8px;
    padding: 6px 12px;
    text-align: center;
  }
}
