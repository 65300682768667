@use "sass:math"; @import "abstract";
.info-text {

  &__text {
    white-space: initial;
  }

  &__icon {
    opacity: 0.1
  }
}
