@use "sass:math"; @import "abstract";
.delete-button {
  opacity: .5;
  transition: opacity 250ms $sharp;
  will-change: opacity;

  &:hover, &:focus {
    opacity: 1;
  }
}
