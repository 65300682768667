@use "sass:math"; @import "abstract";
.registrationForm {

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__checkboxes {
    margin-top: 32px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;

    .button {
      flex: 1;
      margin-left: unset;
      margin-right: unset;
      min-width: fit-content;
    }
  }

  &__submitButton {
    margin-left: auto;
  }

  &__header {
    margin-bottom: 32px;
  }

  &__headerTitle {
    margin-bottom: 4px;

    span {
      color: $color-text-accent;
    }
  }

  &__error {
    color: $color-text-error;
    font-size: 12px;
    margin-bottom: 16px;
    text-align: center;
  }
}

@include mq($until: tablet) {

  .registrationForm {

    &__buttons {
      flex-direction: column;
    }
  }
}
