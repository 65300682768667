@use "sass:math"; @import "abstract";
.heading-breadcrumbs {
  display: flex;

  .button {
    @include mq($from: desktop) {
      height: 53px;
      padding-bottom: 6px;
    }
  }
}

.heading-breadcrumb {
  @include mq($until: tablet) {
    padding-bottom: 10px;
  }
}
