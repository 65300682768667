@use "sass:math"; @import "abstract";
.index-agroservices {
  color: $white;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 4px;

  &__general-card--size {
    @include mqProp('width', calc(100% / 3 - 8px / 3), 100%, 100%);
    @include mqProp('height', 471px, 480px, 460px);
  }

  &__small-card--size {
    @include mqProp('height', 240px, 224px, 286px);
    @include mqProp('width', calc(100% / 3 - 8px / 3), 100%, calc(50% - 2px));
  }

  &__wide-card--size {
    @include mqProp('height', 266px, 254px, 190px);
  }
}

.index-agroservices-container {
  position: relative;
}

.index-agroservices-intersection-element {
  display: block;
  height: 484px;
  left: 0;
  position: absolute;
  top: 484px;
  width: 0;
}
