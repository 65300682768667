@use "sass:math"; @import "abstract";
.imageCropper {

  & > div {
    z-index: 5000;
  }

  &__modal {
    background-color: $color-base-primary;
    left: 50%;
    max-height: 100vh;
    overflow: auto;
    padding: 24px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__closeBtn {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}
