@use "sass:math"; @import "abstract";
$space-y: 24px;
$space-x: 16px;

.additionalSalesMenu {
  background: white;
  border-radius: 8px 8px 0 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: $space-y 0;
  position: absolute;
  width: 100%;

  &__closeBtn {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 46px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 46px;
  }
}

.additionalSalesMenuHeader {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 0 $space-x;

  &__closeBtn {
    cursor: pointer;
    margin: #{-$space-y} #{-$space-x} 0 0;
    padding: 14px;

    svg {
      display: block;
    }
  }
}

.additionalSalesMenuSections {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.additionalSalesMenuSection {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .additionalSalesMenuSectionTitle {
    color: $paleBlack;
    font-weight: 600;
    letter-spacing: 0.1em;
    padding: 0 $space-x;
    text-transform: uppercase;
  }
}

.additionalSalesMenuSectionLinks {

  .additionalSalesMenuSectionLink {
    align-items: center;
    color: $black;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 16px $space-x;

    &__icon {
      margin-right: 8px;
    }

    &__arrow {
      color: $paleBlack;
      margin-left: auto;
    }
  }
}

.additionalSalesMenu-translate {

  &-appear {
    transform: translateY(304px);
  }

  &-appear-active {
    transform: translateY(0);
    transition: transform 160ms ease-in-out;
  }

  &-appear-done {
    transform: translateY(0);
  }

  &-exit-active {
    transform: translateY(304px);
    transition: transform 160ms ease-in-out;
  }
}
