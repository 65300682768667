@use "sass:math"; @import "abstract";
.journalSearchSuggestionCard {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px 44px;

  &__rubricName {
    color: $color-text-tertiary;
  }
}
