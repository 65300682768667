@use "sass:math"; @import "abstract";
.exact-farming-other-offers {
  @include mq($from: desktop) {
    margin-bottom: 128px;
    padding-top: 96px;
  }

  @include mq($from: tablet, $until: desktop) {
    margin-bottom: 96px;
    padding-top: 64px;
  }

  @include mq($until: tablet) {
    margin-bottom: 64px;
    padding-top: 66px;
  }

  &__title {
    @include mq($from: tablet) {
      margin-bottom: 64px;
    }

    @include mq($until: tablet) {
      margin-bottom: 45px;
    }
  }

  &__row {
    @include mq($from: desktop) {
      display: grid;
      gap: 4px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.exact-farming-other-offer-item {
  background: $white;
  position: relative;
  width: 100%;

  @include mq($from: desktop) {
    padding: 32px 33px 48px;
  }

  @include mq($from: tablet, $until: desktop) {
    margin-bottom: 32px;
    padding: 39px 33px 48px;
  }

  @include mq($until: tablet) {
    margin-bottom: 29px;
    padding: 30px $mobilePageWrapperPadding 32px;
  }

  &__exclusive {
    left: 32px;
    position: absolute;
    top: -15px;
  }

  &__condition {
    letter-spacing: 0.1em;
    line-height: 160%;
    opacity: 0.3;
    text-transform: uppercase;

    @include mq($from: desktop) {
      margin-bottom: 13px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 14px;
    }

    @include mq($until: tablet) {
      margin-bottom: 10px;
    }
  }

  &__name {
    font-family: $headingFontFamily;
    font-weight: bold;

    @include mq($from: desktop) {
      margin-bottom: 8px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 13px;
    }

    @include mq($from: tablet) {
      font-size: rem(24);
    }

    @include mq($until: tablet) {
      font-size: mRem(24);
      margin-bottom: 9px;
    }
  }

  &__description {
    @include mq($from: desktop) {
      min-height: 240px;
    }

    @include mq($until: tablet) {
      min-height: 97px;
    }
  }

  &__old-price {
    color: $black;
    font-family: $headingFontFamily;
    font-weight: bold;
    height: 24px;
    opacity: 0.3;
    text-decoration: line-through;

    @include mq($from: desktop) {
      height: 24px;
    }

    @include mq($until: tablet) {
      height: 20px;
    }
  }

  &__price {
    display: inline-block;

    @include mq($until: tablet) {
      font-family: $headingFontFamily;
      font-size: mRem(23);
      font-weight: bold;
      line-height: 140%;
    }
  }

  &__price-per {
    font-family: $headingFontFamily;
    font-style: normal;
    font-weight: bold;
    opacity: 0.4;
  }

  &__bottom-text {
    line-height: 160%;
    
    @include mq($from: desktop) {
      height: 19px;
      margin-bottom: 36px;
    }

    @include mq($from: tablet, $until: desktop) {
      margin-bottom: 26px;
    }
    
    @include mq($until: tablet) {
      margin-bottom: 38px;
    }
  }

  &__actions-row {
    @include mq($until: tablet) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__action-button {
    @include mq($from: tablet) {
      margin-right: 23px;
    }

    @include mq($until: tablet) {
      margin-bottom: 26px;
    }
  }

  &__action-more {
    @include mq($from: tablet) {
      font-size: rem(14);
    }

    @include mq($until: tablet) {
      font-size: mRem(14);
    }
  }
}
