@use "sass:math"; @import "abstract";
.agricultural-product-promo-label {
  line-height: 1;
  padding: 1px 6px 2px;

  &_big {
    text-transform: uppercase;
  }
}

@include mq($until: tablet) {

  .agricultural-product-promo-label {
    padding: 0 4px 2px;
  }
}
