@use "sass:math"; @import "abstract";
.banner-for-complex-order {
  background: $white;
  display: flex;

  &__image {
    @include mqProp('max-width', 215px, 110px);
    @include mqProp('margin', 0 13px 0 12px, 0 2px);
    @include mqProp('padding', 6px 0, 6px 9px 7px 6px);

    flex-shrink: 0;
    height: fit-content;

    @include mq($until: tablet) {
      object-fit: contain;
    }
  }

  &__right {
    padding: 16px 16px 24px 0;
  }

  &__benefits {
    @include mqProp('grid-gap', 16px, 4px 12px);
  }

  &__title {
    transition: color 250ms $sharp;

    @include mq($from: tablet) {
      font-weight: 700;
    }
  }

  &__button {
    display: block;
    margin: 16px 0 0 auto;
    width: 180px;

    @include mq($from: tablet) {
      margin-right: 89px;
    }
  }
}
