@use "sass:math"; @import "abstract";
.search-input {
  display: flex;
  position: relative;
  width: 100%;

  @include mq($until: tablet) {

    svg {
      height: mRem(16);
      width: mRem(16);
    }
  }

  &:not(&_right) {

    .input-wrapper__input {
      @include mqProp('padding-left', 62px, 40px);
    }

    .search-input__clear-button {
      right: 16px;
    }
  }

  &_right {

    .input-wrapper__input {
      @include mqProp('padding-right', 62px, 40px);
    }

    .search-input__clear-button {
      right: 32px;
    }
  }

  &__icon {
    display: flex;
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    z-index: 16;

    &:not(&_right) {
      @include mqProp('left', 30px, 16px);
    }

    &_right {
      @include mqProp('right', 30px, 30px);
    }
  }

  &__clear-button {
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 15;

    svg {
      margin-right: 0;
    }
  }
}
