@use "sass:math"; @import "abstract";
.square-block {
  position: relative;

  @include mq($from: tablet) {
    height: rem(109);
    min-width: rem(140);
    padding: 12px 16px;
  }

  @include mq($until: tablet) {
    height: mRem(90);
    min-width: mRem(140);
    padding: 8px 12px;
  }

  &:not(.skeleton-loader) {
    background: #fff;
    transition: 200ms $sharp;
    transition-property: box-shadow, color;
    will-change: box-shadow, color;
  }

  &__title {
    font-family: $fontFamilyBase;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    color: $green;
    cursor: pointer;
  }

  &::before {
    background-image: url('/assets/icons/flat/arrow-right.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 24px;
    position: absolute;
    width: 24px;

    @include mq($from: tablet) {
      bottom: 10px;
      right: 16px;
    }

    @include mq($until: tablet) {
      bottom: 10px;
      right: 11px;
    }
  }

  &:not(.skeleton-loader) {
    background: #fff;
    transition: 200ms $sharp;
    transition-property: box-shadow, color;
    will-change: box-shadow, color;
  }
}
