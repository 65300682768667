@use "sass:math"; @import "abstract";
.notices {
  position: relative;

  @include mq($until: tablet) {
    font-size: mRem(10);
  }

  &__total {
    @include mq($until: mobile) {
      max-width: 3ch;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__bell {
    align-items: center;
    border-radius: 8px;
    display: flex;
    padding: 4px 8px;
    transition: background-color 200ms $sharp;

    @include mq($from: tablet) {
      color: rgba($white, .4);
    }

    @include mq($until: tablet) {
      color: $black;
    }

    path {
      transition: fill 200ms $sharp;
      will-change: fill;

      @include mq($until: tablet) {
        fill: $black !important;
      }
    }

    span {
      transition: color 200ms $sharp;
      will-change: color;
    }

    &:hover, &:focus {
      background-color: $color-control-bg-secondary-inverted;
      color: $green;

      @include mq($until: tablet) {
        background-color: $color-control-bg-secondary;
      }

      .notices__bell__icon {
        color: $green;
      }
    }

    &__icon {
      color: $white;

      @include mq($from: desktop) {
        margin-right: 8px;
      }

      @include mq($from: tablet, $until: desktop) {
        margin-right: 8px;
        margin-top: 1px;
      }

      @include mq($until: tablet) {
        color: $color-text-secondary;
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
}

.notices-links {
  display: flex;
  flex-direction: column;
  padding-top: rem(16);
  position: absolute;
  right: 0;
  z-index: 50;

  &__link {
    margin-bottom: rem(4);
  }
}
