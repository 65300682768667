@use "sass:math"; @import "abstract";
.file-name-info {
  
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 2rem);
  }

  &__icon {
    color: $green;
  }
}
