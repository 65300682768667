@use "sass:math"; @import "abstract";
.profileActionButton {
  align-items: center;
  border-radius: 8px;
  display: flex;
  line-height: 1;
  padding: 4px 8px;
  transition: color, background-color 200ms $sharp;

  &:hover, &:focus {
    background-color: $color-control-bg-secondary-inverted;
    color: $color-text-accent;
    
    @include mq($until: tablet) {
      background-color: $color-control-bg-secondary;
    }

    .icon-log_in::before {
      color: $color-text-accent !important;
    }
  }

  &__loginButton {
    align-items: center;
    display: flex;
  }

  &__loginIcon {
    margin: 0;
  }
}

@include mq($from: desktop) {

  .profileAction {
    column-gap: 32px;
    display: flex;
  }
}

@include mq($until: desktop) {

  .profileAction {

    &__changeOrgButton--hideUntilDesktop {
      display: none;
    }
  }
}


@include mq($from: tablet) {

  .profileActionButton {
    color: $color-text-inverted;
  }
}

@include mq($from: tablet, $until: desktop) {

  .profileAction {

    &__username {
      max-width: 290px;
      white-space: initial
    }
  }
}

