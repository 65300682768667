@use "sass:math"; @import "abstract";
.faq-nearest-article {
  @include mqProp('padding-top', 32px, 24px);
  @include mqProp('padding-bottom', 56px, 24px);
  @include scaledRem('min-height', 95, 65);

  background-color: $white;
  display: flex;
  position: relative;
  will-change: box-shadow, color;
  transition: {
    duration: 300ms;
    property: box-shadow, color, z-index;
    timing-function: $sharp;
  };

  path {
    will-change: fill, stroke;
    transition: {
      duration: 300ms;
      property: fill, stroke;
      timing-function: $sharp;
    };
  }
  
  &:not(&_empty) {

    &:hover, &:focus {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      z-index: 1;
  
      path:nth-child(1) {
        fill: $green;
      }
  
      path:nth-child(2) {
        stroke: $green;
      }
    }
  }

  &_right {
    @include mqProp('padding-left', 56px, 56px, 40px);
    @include mqProp('padding-right', 56px, 46px, 40px);
  }

  &_left {
    @include mqProp('padding-left', 56px, 38px, 40px);
    @include mqProp('padding-right', 56px, 48px, 40px);
  }

  &__text {

    &_right {
      margin-right: auto;
    }
  }

  &__icon {
    margin-top: -1px;

    &_right {
      margin-left: 24px;
      order: 1;
    }

    &_left {
      margin-right: 24px;
    }
  }
}
