@use "sass:math"; @import "abstract";
.journalSearchDefaultSuggestionCard {
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 12px 16px;

  &__icon {
    color: $color-text-tertiary;
  }

  &__title {
    flex-grow: 1;
  }

  &__close-icon {
    color: $color-text-tertiary;

    &:hover {
      color: $color-text-primary;
    }
  }
}
