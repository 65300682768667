@use "sass:math"; @import "abstract";
@import '../styles';

.agroserviceWideCard {
  width: 100%;
}

.agroserviceWideCardItem {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 70px 40px 40px;


  &.with-blurred-bottom-block::before {
    height: rem(173);
  }

  &__title {
    margin-bottom: 16px;
    max-width: 990px;
  }

  &__text {
    margin-bottom: 16px;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: auto;

    button {
      margin: 0;
    }
  }
}

.agroserviceWideCardItemPrice {
  margin-left: 24px;
  margin-top: auto;
  transform: translateY(-5px);

  &__initial {
    display: inline-block;
    opacity: .5;
    position: relative;

    &::after {
      @include scaledRem('height', 2);

      background-color: $white;
      border-radius: 5px;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(-15deg);
      transform-origin: 100% 13px;
      width: 100%;
    }
  }

  &__period {
    opacity: .7;
  }
}

@include mq($from: tablet, $until: desktop) {

  .agroserviceWideCardItem {
    padding: 40px 32px;

    &.with-blurred-bottom-block::before {
      height: rem(134)
    }

    &__title {
      max-width: 100%;
    }

    &__text {
      margin-bottom: 24px;
      max-width: 400px;
    }
  }

  .agroserviceWideCardItemPrice {
    margin-left: 16px;

    &__period {
      margin-right: 12px;
    }

    &__actual {
      order: -1;
    }
  }
}

@include mq($until: tablet) {

  .agroserviceWideCardItem {
    justify-content: end;
    padding: 32px;

    &__title {
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__text {
      margin-bottom: 24px;
    }

    &__bottom {
      margin-bottom: 20px;
    }
  }

  .agroserviceWideCardItemPrice {
    margin-left: 16px;
    transform: translateY(-6px);

    &__actual {
      align-items: start;
    }
  }
}
