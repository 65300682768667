@use "sass:math"; @import "abstract";
.faq-tree-categories {
  max-height: 771px;
  overflow-y: auto;
  padding-right: 48px;

  &::-webkit-scrollbar {
    @include scaledRem('width', 6);

    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    @include scaledRem('maring-top', 8, 16);
    @include scaledRem('maring-bottom', 8, 16);

    background: #E8E8E8;
  }

  &::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 0;
  }
}
