@use "sass:math"; @import "abstract";
.glass-morph-icon {
  @include scaledRem('height', 80, 64);
  @include scaledRem('width', 80, 64);

  display: block;
  flex-shrink: 0;
  position: relative;

  &_medium {

    &-sm {
      @include mq($until: tablet) {
        height: mRem(56);
        width: mRem(56);
      }
    }
  }

  &_small {

    &-sm {
      @include mq($until: tablet) {
        height: mRem(48);
        width: mRem(48);
      }
    }
  }

  &, &::before {
    background-position: center;
    background-repeat: no-repeat;

    @include mq($from: tablet) {
      background-size: rem(68);
    }

    @include mq($until: tablet) {
      background-size: contain;
    }
  }

  &::before {
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 200ms $sharp;
    width: 100%;
    will-change: opacity;
  }
}
