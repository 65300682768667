@use "sass:math"; @import "abstract";
.signed-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.signed-detail {
  display: flex;
  flex-direction: column;

  &__sign {
    @include mq($from: tablet, $until: desktop) {
      font-family: $fontFamilyBase;
      font-size: rem(12) !important;
    }
  }
}
