@use "sass:math"; @import "abstract";
.authRegistrationDocument {

  &__text {

    button {
      display: inline-block;
      min-height: 0;
      min-width: 0;
      padding: 0;
    }
  }
}

@include mq($from: desktop) {

  .authRegistrationDocument {
    margin-bottom: 24px;
  }
}

@include mq($from: tablet, $until: desktop) {

  .authRegistrationDocument {
    margin-bottom: 24px;
  }
}
