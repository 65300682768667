@use "sass:math"; @import "abstract";
.wide-breadcrumbs {
  background-color: $white;
  width: 100%;

  @include mq($from: desktop) {
    margin-top: -20px;
    padding: 20px 0 32px;
  }

  @include mq($until: desktop) {
    margin-top: 23px;
    padding: 24px 0;
  }

  .breadcrumbs {
    @include mq($until: tablet) {
      margin-bottom: 1.3rem;
      margin-top: mRem(-29);
      transform: translateY(-34px);
    }
  }

  .breadcrumb {

    &:first-child, &_link.breadcrumb_link.breadcrumb_link:not(.breadcrumb_back) {
      color: $black;

      @include mq($from: tablet) {
        color: $paleBlack;
      }
    }

    &:last-child {
      color: $black !important;
    }
  }

  &__wrapper {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  &__back-btn {
    @include mq($from: tablet) {
      margin-top: 40px;
    }
  }
}
