@use "sass:math"; @import "abstract";
.cart-action {
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 4px 8px;

  @include mq($from: desktop) {
    color: rgba($white, .4);
    margin-left: 8px;
  }

  @include mq($from: tablet, $until: desktop) {
    color: rgba($white, .4);
    margin-top: 1px;
  }

  @include mq($until: tablet) {
    color: $black;
    font-size: mRem(10);
  }

  @include mq($until: mobile) {
    margin-left: 8px;
  }

  path, circle {
    transition: stroke 200ms $sharp;
    will-change: stroke;

    @include mq($until: tablet) {
      stroke: $black !important;
    }
  }

  &:hover, &:focus {
    background-color: $color-control-bg-secondary-inverted;
    color: $green !important;

    @include mq($until: tablet) {
      background-color: $color-control-bg-secondary;
    }

    .cart-action__icon {
      color: $green;
    }
  }

  &__icon {
    color: $white;
    display: flex;

    @include mq($until: tablet) {
      color: $color-text-secondary;
      font-size: 16px;
    }

    @include mq($from: tablet) {
      margin-right: rem(10);
    }
  }

  &__quantity {
    @include mq($until: tablet) {
      margin-left: 4px;
    }

    .spinner_static {
      width: 12px;
    }
  }


  .spinner-content::after {
    @include mq($from: tablet) {
      background-color: $black;
    }
  }
}

.cart-spacer {
  @include scaledRem('width', 67, 25);

  &_auth {
    @include scaledRem('width', 61, 25);
  }
}

