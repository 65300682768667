@use "sass:math"; @import "abstract";
.lot-qualities {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.lot-quality {
  display: flex;
  max-width: 100%;

  &__value {
    font-weight: 700;
    margin-left: 4px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not(:last-child) {
    @include mqProp('margin-right', 16px, 12px);
  }
}
