@use "sass:math"; @import "abstract";
.cookie-agreement {
  @include mqProp('min-height', 94px, 76px);

  align-items: center;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  color: $black;
  display: flex;
  padding: 6px 12px;
  transition: 250ms ease-in-out;
  transition-property: transform, opacity;
  width: rem(400);
  will-change: opacity;


  @include mq($until: tablet) {
    width: 100%;
  }


  &__text {

    .underline {
      --link-color: $black;
    }
  }

  &__button {
    @include scaledRem('height', 40, 34);
    @include scaledRem('width', 81, 87);

    min-width: 0;

    @include mq($until: tablet) {
      margin-left: 12px;
      margin-top: -5px;
      min-height: 0;
    }
  }
}

body:has([data-modal-type='AuthModal']) .cookie-agreement {
  visibility: hidden;
}
