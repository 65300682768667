@use "sass:math"; @import "abstract";
.notice {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  &__icon {
    align-items: flex-start;
    display: flex;
  }
}
