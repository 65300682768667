@use "sass:math"; @import "abstract";
.rubricsMenu {
  overflow-x: auto;
  padding-bottom: 24px;
  padding-top: 24px;
  scrollbar-width: thin;


  @include mq($until: tablet) {
    padding-bottom: 18px;
    padding-top: 18px;
  }


  &__list {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    white-space: nowrap;

    @include mq($until: tablet) {

      &:not(:first-child) {
        margin-left: 26px;
      }
    }

    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}

.rubricsMenu__item-loader {
  @include mq($until: tablet) {
    height: 20px;
    width: 57px;
  }

  @include mq($from: tablet) {
    height: 24px;
    width: 80px;
  }

  @include mq($until: tablet) {

    & + & {
      margin-left: 26px;
    }
  }

  @include mq($from: tablet) {

    & + & {
      margin-left: 68px;
    }
  }
}

.rubricsMenu__shadow {
  @include mqProp('width', 38px, 16px);

  flex-shrink: 0;
  position: sticky;
  z-index: 50;

  &--left {
    background: linear-gradient(to right, $lightGray, rgba($lightGray, 0.8), rgba($lightGray, 0));
    left: 0;
  }

  &--right {
    background: linear-gradient(to left, $lightGray, rgba($lightGray, 0.8), rgba($lightGray, 0));
    right: 0;
  }
}
