@use "sass:math"; @import "abstract";
.banner-to-bargain {
  background-color: $white;
  display: flex;

  @include mq($from: tablet) {
    align-items: center;
    padding: 8px 32px;
  }

  @include mq($until: tablet) {
    align-items: flex-start;
    padding: 12px $mobilePageWrapperPadding;
  }

  &__title {
    font-family: $headingFontFamily;
    font-weight: bold;

    @include mq($until: tablet) {
      margin-bottom: 7px;
    }
  }
}
