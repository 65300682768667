@use "sass:math"; @import "abstract";
.exact-farming-screen-section {
  @include mq($from: desktop) {
    margin-bottom: 5px;
    min-height: 1024px;
  }

  @include mq($from: tablet) {
    padding-top: 96px;
  }

  @include mq($until: tablet) {
    padding-top: 64px;
  }

  &__image-wrapper {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.exact-farming-screen-section-text-row {
  @include mq($from: desktop) {
    display: flex;
    margin-bottom: 48px;
  }

  @include mq($from: tablet, $until: desktop) {
    margin-bottom: 34px;
  }

  @include mq($until: tablet) {
    margin-bottom: 25px;
    margin-top: 18px;
  }

  &__item {
    padding-left: 42px;
    position: relative;

    @include mq($from: desktop) {
      width: 33.3%;

      &:not(:last-child) {
        padding-right: 38px;
      }
    }

    @include mq($from: tablet, $until: desktop) {

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    @include mq($from: tablet) {
      font-family: $headingFontFamily;
      font-size: rem(18);
      font-weight: bold;
      line-height: 150%;
    }

    @include mq($until: tablet) {
      font-size: mRem(14);
      line-height: 140%;

      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    &::before {
      background-image: url('/assets/icons/check-green.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: 12px;
      left: 6px;
      position: absolute;
      top: 6px;
      width: 16px;
    }
  }
}
